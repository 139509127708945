import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { environment, APP_CONFIG } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { InterceptorService } from './core/services/interceptor.service';
import { SetupGuardService } from './core/guards/setup-guard.service';

import { CoreModule } from './core/core.module';
import { CoreComponentModule } from './core/components/core-component.module';
import { AuthenticationModule } from './authentication/authentication.module';

import { RightSideMenuModule } from './right-menu/right-menu.module';
import { CharterNotificationsPageModule } from './charter/features/charter-notifications/charter-notifications.module';

import { PipesModule } from './shared/pipes/pipes.module';
import { SharedComponentModule } from '@app/shared/components/shared-component.module';
import { SharedDirectiveModule } from './shared/directives/shared-directive.module';

import { FlyingDatetimePipe } from './shared/pipes/flying-datetime.pipe';
import { ReversePipe } from './shared/pipes/reverse.pipe';

import { MbscModule } from '@mobiscroll/angular';
import { IonIntlTelInputModule } from 'ion-intl-tel-input';
import { Storage as IonicStorageModule } from '@ionic/storage-angular';

import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { AppStateService } from './app-state.service';

// import { Pro } from '@ionic';
// Pro.init('02995997', {
//   appVersion: APP_CONFIG.WebVersion,
// });
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase_config),

    CoreModule,
    CoreComponentModule,
    AuthenticationModule,
    RightSideMenuModule,
    CharterNotificationsPageModule,

    PipesModule,
    SharedComponentModule,
    SharedDirectiveModule,

    MbscModule,
    IonIntlTelInputModule,
  ],
  exports: [
    SharedComponentModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },

    IonicStorageModule,
    SetupGuardService,
    FlyingDatetimePipe,
    ReversePipe,

    AppVersion,
    FileOpener,
    FileTransfer,
    Network,
    InAppBrowser,
    Keyboard,
    Chooser,
    AppStateService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
