
export class NavbasicSetup {
    public stations: any;
    public VendorType: string = "Trip Support";
    public currency: any;
    public taxList: any;
    public NavigationNotes: string;
    constructor(navigationData?: any, element?: any , ) {
        if (navigationData) {
            this.stations = navigationData.Country.CountryID;
            this.VendorType = navigationData.VendorType
            this.currency = navigationData.Currency;
            this.taxList = element.Taxes;
            this.NavigationNotes = navigationData.Oprationalnotes;
        }
    }
}

export class Communication {
    public CommunicationSurchageid: number;
    public name: string;
    public CommunicationCurrency: string;
    public CommunicationUnitType: string;
    public currprice: string;
    public price: string;
    public flag: string;
    public value: boolean;
    public type: string;

    constructor(CommunicationSurchageid: number, name: string, CommunicationCurrency: string, CommunicationUnitType: string, currprice: string, price: string, flag: string, value: boolean, type: string) {
        this.CommunicationSurchageid = CommunicationSurchageid;
        this.name = name;
        this.CommunicationCurrency = CommunicationCurrency;
        this.CommunicationUnitType = CommunicationUnitType;
        this.currprice = currprice;
        this.price = price;
        this.flag = flag;
        this.value = value;
        this.type = type;
    }
}
export class ThirdPartyDisbursement {
    public ThirdPartyDisbursementid: number;
    public name: string;
    public ThirdPartyDisbursementCurrency: string;
    public ThirdPartyDisbursementUnitType: string;
    public currprice: string;
    public price: string;
    public flag: string;
    public value: boolean;
    public type: string;

    constructor(ThirdPartyDisbursementid: number, name: string, ThirdPartyDisbursementCurrency: string, ThirdPartyDisbursementUnitType: string, currprice: string, price: string, flag: string, value: boolean, type: string) {
        this.ThirdPartyDisbursementid = ThirdPartyDisbursementid;
        this.name = name;
        this.ThirdPartyDisbursementCurrency = ThirdPartyDisbursementCurrency;
        this.ThirdPartyDisbursementUnitType = ThirdPartyDisbursementUnitType;
        this.currprice = currprice;
        this.price = price;
        this.flag = flag;
        this.value = value;
        this.type = type;
    }
}

export class SupervisionCharges {
    public SupervisionSurchargeid: number;
    public name: string;
    public SupervisionChargesCurrency: string;
    public SupervisionChargesUnitType: string;
    public currprice: string;
    public price: string;
    public flag: string;
    public value: boolean;
    public type: string;

    constructor(SupervisionSurchargeid: number, name: string, SupervisionChargesCurrency: string, SupervisionChargesUnitType: string, currprice: string, price: string, flag: string, value: boolean, type: string) {
        this.SupervisionSurchargeid = SupervisionSurchargeid;
        this.name = name;
        this.SupervisionChargesCurrency = SupervisionChargesCurrency;
        this.SupervisionChargesUnitType = SupervisionChargesUnitType;
        this.currprice = currprice;
        this.price = price;
        this.flag = flag;
        this.value = value;
        this.type = type;
    }
}
export class AdminFee {
    public AdminFeeid: number;
    public name: string;
    public AdminFeeCurrency: string;
    public AdminFeeUnitType: string;
    public currprice: string;
    public price: string;
    public flag: string;
    public value: boolean;
    public type: string;

    constructor(AdminFeeid: number, name: string, AdminFeeCurrency: string, AdminFeeUnitType: string, currprice: string, price: string, flag: string, value: boolean, type: string) {
        this.AdminFeeid = AdminFeeid;
        this.name = name;
        this.AdminFeeCurrency = AdminFeeCurrency;
        this.AdminFeeUnitType = AdminFeeUnitType;
        this.currprice = currprice;
        this.price = price;
        this.flag = flag;
        this.value = value;
        this.type = type;
    }
}
export class SetupFee {
    public SetupFeeid: number;
    public name: string;
    public SetupFeeCurrency: string;
    public SetupFeeUnitType: string;
    public currprice: string;
    public price: string;
    public flag: string;
    public value: boolean;
    public type: string;

    constructor(SetupFeeid: number, name: string, SetupFeeCurrency: string, SetupFeeUnitType: string, currprice: string, price: string, flag: string, value: boolean, type: string) {
        this.SetupFeeid = SetupFeeid;
        this.name = name;
        this.SetupFeeCurrency = SetupFeeCurrency;
        this.SetupFeeUnitType = SetupFeeUnitType;
        this.currprice = currprice;
        this.price = price;
        this.flag = flag;
        this.value = value;
        this.type = type;
    }
}
export enum SURCHARGE_NAMES {
    Communication = 'Communication',
    ThirdPartyDisbursement = 'ThirdPartyDisbursement',
    SupervisionCharges = 'SupervisionCharges',
    AdminFee = 'AdminFee',
    SetupFee = 'SetupFee',
}