import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SpinnerService } from '@app/estimates/services/spinner.service';

@Component({
  selector: 'app-custom-spinner',
  templateUrl: './custom-spinner.component.html',
  styleUrls: ['./custom-spinner.component.scss'],
})
export class CustomSpinnerComponent implements OnInit {
  public message: string = 'Please wait...';
  public isVisible: boolean = false;
  constructor(private spinnerService: SpinnerService) { }
  public ngOnChanges(changes: SimpleChanges) {
  }
  public ngOnInit() {
    this.spinnerService.message$.subscribe(message => {
      this.message = message;
    });

    this.spinnerService.isVisible$.subscribe(isVisible => {
      this.isVisible = isVisible;
      if (isVisible) {
        document.body.classList.add('spinner-active');
      } else {
        document.body.classList.remove('spinner-active');
      }
    });
  }

}
