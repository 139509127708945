import { Deserializable } from './deserializable.model';
import {
  AirportServiceVendors,
  FuelLastStationDetails,
  HandlerServiceVendors,
  PermitServiceVendors,
  ServiceVendorModel,
  StationDetails,
} from './schedule-model';
import * as timezones from 'moment-timezone';

export class VendorListData {
  public vendorId: number;
  public vendorName: string;
  public firebaseId: any;
  public vendorContactNo: number;
  public vendorcallingCodes: string;
  public vendorAddressline1: string;
  public vendorAddressline2: string;
  public vendorAddressline3: string;
  public pincode: string;
  public state: string;
  public countryname: string;
  public accountContact = new Contact();
  public operationContact = new Contact();
  public logo: string;
  public vendorDescription: string;
  public services: Services;
  public price: Prices[];
  public grandTotal: number;
  public GrandTotal: number;
  public servicename: string;
  public VendorDetails?: string;
  public ResellerName?: string;
  public TypeofVendor?: string;
  public resellerEmail?: string;
  public resellerNumber?: number;
  public resellerCallingCode?: string;
  public resellerSet?:boolean;
  public rating?:number;
  constructor(
    vendorId: number,
    vendorName: string,
    firebaseId: number,
    vendorContactNo: number,
    vendorcallingCodes: string,
    vendorAddressline1: string,
    vendorAddressline2: string,
    vendorAddressline3: string,
    pincode: string,
    state: string,
    countryname: string,
    accountContact: Contact,
    operationContact: Contact,
    logo: string,
    vendorDescription: string,
    services: Services,
    price: Prices[],
    GrandTotal: number,
    servicename: string,
    VendorDetails?: string,
    ResellerName?: string,
    TypeofVendor?: string,
    resellerEmail?: string,
    resellerNumber?: number,
    resellerCallingCode?: string,
    resellerSet?:boolean,
    rating?:number,
  ) {
    this.vendorId = vendorId;
    this.vendorName = vendorName;
    this.firebaseId = firebaseId;
    this.vendorContactNo = vendorContactNo;
    this.vendorcallingCodes = vendorcallingCodes;
    this.vendorAddressline1 = vendorAddressline1;
    this.vendorAddressline2 = vendorAddressline2;
    this.vendorAddressline3 = vendorAddressline3;
    this.pincode = pincode;
    this.state = state;
    this.countryname = countryname;
    this.accountContact = accountContact;
    this.operationContact = operationContact;
    this.logo = logo;
    this.vendorDescription = vendorDescription;
    this.services = services;
    this.price = price;
    this.servicename = servicename;
    this.VendorDetails = VendorDetails;
    this.ResellerName = ResellerName;
    this.TypeofVendor = TypeofVendor;
    this.resellerEmail = resellerEmail;
    this.resellerNumber = resellerNumber;
    this.resellerCallingCode = resellerCallingCode;
    this.resellerSet = resellerSet;
    this.rating = rating;
    this.GrandTotal = GrandTotal;
  }
}
export class Services {
  public handlig = [];
  public permit = [];
  public fuel = [];
}
export class Prices {
  public serviceName: string;
  public price = new Array<PriceStru>();
  public fees = new Array<PriceStru>();
  public surcharges = new Array<PriceStru>();
  public taxes = new Array<PriceStru>();
  public carbonofset = new Array<PriceStru>();
  public grandTotal = new Array<PriceStru>();
  public productTotal = new Array<PriceStru>();
  public clientDetails = new Array<PriceStru>();

  constructor(servicename?: string) {
    this.serviceName = servicename;
  }
}
export class PriceStru {
  public name: string;
  public price: number;
  public type?: string;
  public value?: number;
  public subTotal: number = 0;
  public countryname?: string;
  public entrypoint?: string;
  public exitpoint?: string;
  public distance: number;
  public time: number;
  public surchargessubtotal: number;
  public taxesSubtotal: number;
  public total: number;
  public revisionsArr = new Array<RevisionInfo>();
  public currencyValue: string;
  public fuelUnit: string;
  public pricetype?: string;

  constructor(
    name: string,
    price: number,
    priceArr: PriceStru[],
    type?: string,
    value?: number,
    countryname?: string,
    entrypoint?: string,
    exitpoint?: string,
    distance?: number,
    time?: number,
    surchargessubtotal?: number,
    taxesSubtotal?: number,
    total?: number,
    revisionsArr?: RevisionInfo[],
    currencyValue?: string,
    fuelUnit?: string,
    pricetype?: string
  ) {
    this.name = name;
    this.countryname = countryname;
    this.price = price || 0;
    this.revisionsArr = revisionsArr;
    this.currencyValue = currencyValue;
    this.fuelUnit = fuelUnit;
    if (type) {
      this.type = type;
    }
    if (value) {
      this.value = value;
    }
    priceArr.forEach((element: PriceStru) => {
      if (String(element.price) != 'IBH') {
        this.subTotal += Number(element.price) || 0;
      }
    });
    this.entrypoint = entrypoint;
    this.exitpoint = exitpoint;
    this.distance = distance;
    this.time = time;
    this.surchargessubtotal = surchargessubtotal;
    this.taxesSubtotal = taxesSubtotal;
    this.total = total;
    this.pricetype = pricetype;
  }
}
export class RevisionInfo {
  public name?: string;
  public price?: number;
  public Taxes?: RevisionTaxes[];
  constructor(name?: string, price?: number, Taxes?: RevisionTaxes[]) {
    this.name = name;
    this.price = price;
    this.Taxes = Taxes;
  }
}
export class RevisionTaxes {
  public DisplayPrice: number;
  public Price: number;
  public TaxName: string;
  public Type: string;
  constructor(
    DisplayPrice: number,
    price: number,
    TaxName: string,
    Type: string
  ) {
    this.DisplayPrice = DisplayPrice;
    this.Price = price;
    this.TaxName = TaxName;
    this.Type = Type;
  }
}
export class Contact {
  public contact: number;
  public name: string;
  public email: string;
  public callingCode:string;
  constructor(contact?: number, name?: string, email?: string,callingCode?:string) {
    this.contact = contact;
    this.name = name;
    this.email = email;
    this.callingCode = callingCode;
  }
}
export class ServiceProviderList {
  public cbp = new Array<ServiceVendorModel>();
  public permit = new Array<PermitServiceVendors>();
  public handling = new Array<HandlerServiceVendors>();
  public fuel = new Array<HandlerServiceVendors>();
  public airportcharges = new Array<AirportServiceVendors>();
}
export class ResponseStatus {
  public permit: boolean;
  public cbp: boolean;
  public handlingArrival: boolean;
  public handlingDep: boolean;
  public airportChargesArrival: boolean;
  public airportChargesDep: boolean;
  public fuel: boolean;
}
export class GetQuoteBackendFormat implements Deserializable {
  public from: StationDetails;
  public to: StationDetails;
  public departDate: string;
  public timeType: string;
  public flightRule: string;
  public flightType: string;
  public operationType: string;
  public BLKTIME: string;
  public DIST: number;
  public ETDGMT: string;
  public ETDLT: string;
  public ETAGMT: string;
  public ETALT: string;
  public legRouteid: string;
  public etops: string;
  public country: any[];
  public aircraft: string;
  public flightPlanresponseID: string;
  public crew: number;
  public pax: number;
  public legId?: number;
  public removed?: boolean;
  public fuelOnLast: FuelLastStationDetails;
  public etopsEnabled: string;
  public etopsMinutes: number;
  constructor(
    from: StationDetails,
    to: StationDetails,
    departDate: string,
    timeType: string,
    flightRule: string,
    flightType: string,
    operationType: string,
    BLKTIME: string,
    DIST: number,
    ETDGMT: string,
    ETDLT: string,
    ETAGMT: string,
    ETALT: string,
    legRouteid: string,
    etops: string,
    country: any[],
    aircraft: string,
    flightPlanresponseID: string,
    crew: number,
    pax: number,
    removed?: boolean,
    legId?: number,
    etopsAdv?: string,
    etopsMinsAdv?:string,
    fuelLast?: FuelLastStationDetails,
  ) {
    this.from = from;
    this.to = to;
    this.fuelOnLast = fuelLast;
    // if (timeType == 'true') {
    //   this.departDate = this.covertUTCtime(from.timeZone, departDate);
    // } else {
    //   this.departDate = departDate;
    // }
    this.departDate = this.covertUTCtime(from.timeZone, departDate);

    this.timeType = timeType;
    this.flightRule = flightRule;
    this.flightType = flightType;
    this.operationType = operationType;
    this.BLKTIME = BLKTIME;
    this.DIST = DIST;
    this.ETDGMT = ETDGMT;
    this.ETDLT = ETDLT;
    this.ETAGMT = ETAGMT;
    this.ETALT = ETALT;
    this.legRouteid = legRouteid;
    this.etops = etops;
    this.country = country;
    this.aircraft = aircraft;
    this.flightPlanresponseID = flightPlanresponseID;
    this.crew = crew;
    this.pax = pax;
    this.removed = removed;
    this.legId = legId;
    this.etopsEnabled = etopsAdv ? this.getFormatedValue(etopsAdv): this.etopsEnabled;
    this.etopsMinutes = parseInt(etopsMinsAdv, 10);
  }
  public deserialize(input: any): this {
    return Object.assign(this, input);
  }
  public covertUTCtime(timeZone, time) {
    return timezones.tz(time, timeZone).utc().format();
  }
  public getFormatedValue(literal: string) {
    const firstChar = literal?.charAt(0)?.toUpperCase();
    const remainingString = literal?.slice(1);
    return literal ? firstChar + remainingString : 'No';
  }
}

export enum PriceBreakupConst {
  FlightPlanning = 'Flight Planning',
  OverflightAndNavigation = 'Overflight Navigation',
  Overflight = 'Ovf Permits',
  Handling = 'Handling',
  Navigation = 'Navigation',
  PaxServices = 'Pax Services',
  CrewServices = 'Crew Services',
  RampServices = 'Ramp Services',
  Fuel = 'Fuel Uplift',
  CarbonOffset = 'Carbon Offset',
  ActualFuelUplift = 'Fuel Price',
  AirportCharges = 'Apt Fees',
  OtherCharges = 'Other Charges',
  Surcharges = 'Surcharges',
  LandingSurcharges = 'Landing Surcharges',
  ParkingSurcharges = 'Parking Surcharges',
  Slot = 'Slot',
  Permission = 'Permission',
  ProdOfFuelUpliftFuelPrice = 'Fuel Uplift * Fuel Price',
  Airpermission = 'Apt Permits',
  Revisions = 'Revisions',
}
export enum ServicesConst {
  FlightPlanning = 'flightplanning',
  OvfPermit = 'permit',
  GroundHandling = 'handling',
  Fuel = 'fuel',
  AirPortCharges = 'airportcharges',
  OvfNav = 'Overflight Navigation',
  OverflightPermit = 'Overflight Permits',
  FuelPrice = 'Fuel Price',
  FuelUplift = 'Fuel Uplift',
  LandingSur = 'Landing Surcharges',
  ParkingSur = 'Parking Surcharges',
  SG = 'Specificgravity',
  Vendor = 'Vendor',
  Unitprice = 'UnitPrice',
  Client = 'client',
  DepPermits = 'Apt Permits',
}

export interface VendorInfoRateBreakUpResponse {
  ResponseCode: string;
  APIName: string;
  GetVendorInfo: GetVendorInfo;
  ResponseStatus: string;
}
export interface GetVendorInfo {
  VendorData: VendorData;
  Price: Price[];
}
export interface VendorData {
  Pincode: string;
  State: string;
  vendorcallingCodes: string;
  vendorOpscallingCodes: string;
  vendoraccountcallingCodes: string;
  VendorName: string;
  VendorContactNo: string;
  VendorOperationContactNo: string;
  VendorOperationContactEmailID: string;
  CompanyName: string;
  logo: string;
  VendorOperationContactName: string;
  VendorAccDeptContactEmailID: string;
  VendorAccDeptContactNo: string;
  VendorAccDeptContactName: string;
  VendorServicesCat: string;
  VendorInvoiceAddressline1: string;
  VendorInvoiceAddressline2: string;
  VendorInvoiceAddressline3: string;
  Description: string;
  CountryName: string;
  firebaseID: string;
}

export interface Tax {
  TaxName: string;
  Type: string;
  Price: string;
  DisplayPrice: number;
}

export interface Price {
  price: number;
  TripType: string;
  Cost: string;
  Taxes: Tax[];
}