/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: true,
  firebase_config: {
    apiKey: 'AIzaSyArEmDe2NW4Y0mUaZLuqRyehNfvZEji1WA',
    authDomain: 'avplat-7dd2c.firebaseapp.com',
    databaseURL: 'https://avplat-7dd2c.firebaseio.com',
    projectId: 'avplat-7dd2c',
    storageBucket: 'avplat-7dd2c.appspot.com',
    messagingSenderId: '1077468722602',
  },
};
export const MapConfig = {
  googleAPIKey: 'AIzaSyAFblizV8dBXm1OlUq6JNVzQKVKKCmlov0'
}
export const APP_CONFIG = {
  MyURL: '',
  URL: '',
  FlpURL: '',
  WebVersion: '3.6.1',
};
export class AppupdateConfig {
  public MinAndroidVersion: string;
  public MinIosVersion: string;
  public MinWebversion: string;
  public url1: {
    FlpUrl: string;
    max: string;
    min: string;
    url: string;
  };
  public url2: {
    FlpUrl: string;
    max: string;
    min: string;
    url: string;
  };
}