import { ConstantNames, SkeltonDisplayLines } from './../../constants/constant';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skelton',
  templateUrl: './skelton.component.html',
  styleUrls: ['./skelton.component.scss'],
})
export class SkeltonComponent implements OnInit {
  @Input('lineno') public linenos: number;
  @Input('showAvatar') public showavatar: boolean;
  @Input('showSideIcon') public sideIcon: boolean;
  @Input('showFirstCol') public showFirstCol: boolean;
  @Input('type') public pageType?: string;
  @Input('userType') public userType?: string;
  @Input('skelton') public skelton: boolean = true;
  @Input('legInfoOnDateChange') public legInfoOnDateChange: any[] = [];
  @Input('tripType') public tripType: string = 'oneWay';
  public configButton = ConstantNames;
  public itemsListCount = [1, 2, 3, 4, 5];
  public fakeItems: any[] = new Array(20);
  public skeltonLines: any = SkeltonDisplayLines;
  constructor() {}

  public ngOnInit() {
    this.addNewLine();
    this.setSkeltonLine();

  }
  public setSkeltonLine() {
    if (this.pageType == 'invoices-details') {
      this.skeltonLines = [
        {
          showLine: true,
          lineNo: 1,
          maxLineWidth: '40%',
          maxLineWidth2: '20%',
        },
        { showLine: true, lineNo: 2, maxLineWidth: '40%', maxLineWidth2: '5%' },
      ];
    } else if (this.pageType == 'client-user') {
      this.skeltonLines = [
        {
          showLine: true,
          lineNo: 1,
          maxLineWidth: '80%',
          maxLineWidth2: '20%',
        },
        { showLine: true, lineNo: 2, maxLineWidth: '60%', maxLineWidth2: '5%' },
        { showLine: true, lineNo: 2, maxLineWidth: '50%', maxLineWidth2: '5%' },
      ];
    } else if (this.pageType == 'client-role') {
      this.skeltonLines = [
        {
          showLine: true,
          lineNo: 1,
          maxLineWidth: '60%',
          maxLineWidth2: '20%',
        },
      ];
    } else if (this.pageType == 'edit-role') {
      this.skeltonLines = [];
      const checkboxSkelton = [
        { showLine: true, lineNo: 1, maxLineWidth: '40%' },
      ];
      for (let i = 0; i <= 15; i++) {
        this.skeltonLines.push(checkboxSkelton);
      }
    }
  }
  public addNewLine() {
    if (this.linenos == 5) {
      const newLine = { showLine: true, lineNo: 3, lineWidth: '40%' };
      let count = 0;
      const reducer = (acc, currLine) => {
        if (count == 2) {
          newLine.lineNo = count;
          acc = [...acc, newLine];
          count++;
        }
        currLine.lineNo = count;
        count++;
        return [...acc, currLine];
      };
      this.skeltonLines = this.skeltonLines.reduce(reducer, []);
    }
  }
  public ionViewDidLeave() {
    this.fakeItems = [];
    this.skeltonLines = [];
  }
  public ionViewWillEnter() {}
}
