import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '../constants/constant';
import firebase from 'firebase';
import {
  AllStationJson,
  Station,
} from '@app/trips/schedule-creation/model/create-schedule';
import { catchError } from 'rxjs/operators';
import { CommonService } from './common.service';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public message: any = 'default message';
  public data: any; // variable for trip details
  public TripData: any; // variable for trip list
  public AirCraftList: any; // fetching aircrafts
  public listOfAirports: any;
  public routeDetails: any;
  public userdetails: any;
  public APP_URL = '';
  public selectedStationItem: any;
  public allStation$ = new Array<Station>();
  public stationpath = 'assets/country.json';
  public type = ApiTypes;
  public stationData = new AllStationJson();
  public storeAltStn:any;
  public storeAltStn2:any;
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private exceptionService: ExceptionService,
    private serverConnectService: ServerConnectService
  ) {
  }

  public getListOfCountry() {
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.type.GET,
          'assets/country.json',
          '',
          false,
          false,
          false,
          'assets'
        )
        .then((res: any) => {
          resolve(res.data);
        });
    });
  }

  public getListOfAircraftPerformanceBased() {
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.type.GET,
          'assets/api_aircraft_performance.json',
          '',
          false,
          false,
          false,
          'assets'
        )
        .then((res: any) => {
          resolve(res.data);
        });
    });
  }

  public getMessage() {
    return (this.message = 'this is message from data service page');
  }

  public getListOfStation() {
    return new Promise((resolve) => {
      this.fetchStations(resolve)
      // if (this.stationData.data) {
      //   resolve(this.stationData.data);
      // } else {
      //   this.dataFetched = false;
      //   this.fetchStations();
      //   const checkData = () => {
      //     if (this.dataFetched) {
      //       resolve(this.stationData.data);
      //     } else {
      //       setTimeout(checkData, 100);
      //     }
      //   };
      //   setTimeout(checkData, 100);
      // }
    });
  }
  // public StationList() {
  //   return this.http
  //     .get<AllStationJson>(this.stationData)
  //     .pipe(catchError(this.exceptionService.handleError));
  // }
  public filterArr(stationData: Station[], destination: Station) {
    const destLat = destination?.Latitude;
    const destLng = destination?.Longitude;
    const newArr = [];
    stationData.map((item: Station) => {
      newArr.push({
        AirportID: item.AirportID,
        AirportName: item.AirportName,
        Airportname: item.AirportName,
        CityName: item.CityName,
        Country: item.Country,
        IATACode: item.IATACode,
        FAAIdent: item.FAAIdent,
        ICAOCode: item.ICAOCode,
        Latitude: item.Latitude,
        Longitude: item.Longitude,
        Elevation: item.Elevation,
        UTC: item.UTC,
        DST: item.DST,
        flag: item.flag,
        timezone: item.timezone,
        verified: item.verified,
        searchIndexitem: item.searchIndexString,
        Runways: item.Runways,
        RunwaysUnit: item.RunwaysUnit,
        maxRunwayLength: item.maxRunwayLength,
        Distance: this.commonService.calcCrow(
          destLat,
          destLng,
          item.Latitude,
          item.Longitude
        ),
      });
    });
    return newArr.sort((a, b) => Number(a.Distance) - Number(b.Distance));
  }
  public getStationList$() {
    return new Promise((resolve) => {
      if (this.allStation$.length > 0) {
        resolve(this.allStation$);
      } else {
        // this.StationList().subscribe(
          // (list: AllStationJson) => {
            // this.allStation$ = this.stationData.data;
            // resolve(this.allStation$);
            this.fetchStations(resolve)

          // },
        // );
      }
    });
  }

  public getListOfAircraft() {
    return new Promise((resolve) => {
      const listOfAirport = this.http.get('assets/api_aircraft.json').subscribe(
        (staData: any) => {
          resolve(staData.data);
        },
        (err) => {
          resolve('Error');
        }
      );
    });
  }

  public gettimezone() {
    return new Promise((resolve) => {
      const listOfStation = this.http.get('../assets/timezone.json').subscribe(
        (timezone: any) => {
          resolve(timezone.data);
        },
        (err) => {
          resolve('Error');
        }
      );
    });
  }

  public getAdequateStations(adequateStationList = []) {
    return new Promise((resolve) => {
      const tempArray = [];
      const filteredStationListArr = [];
      this.getListOfStation().then((data: any) => {
        adequateStationList.map((ICAOCode: string) => {
          const filteredStationList = data.filter((station: Station) => {
            return station.ICAOCode == ICAOCode;
          });
          filteredStationListArr.push(filteredStationList[0]);
        });
        filteredStationListArr.map((station: Station) => {
          tempArray.push({
            AirportName: station.AirportName,
            CityName: station.CityName,
            Country: station.Country,
            Elevation: station.Elevation,
            ICAOCode: station.ICAOCode,
            Latitude: station.Latitude,
            Longitude: station.Longitude,
            ReferencePosition: {
              Elevation: station.Elevation,
              Latitude: station.Latitude,
              Longitude: station.Longitude,
            },
          });
        });
        resolve(tempArray);
      });
    });
  }
  public fetchStations(resolve?)
  {
    firebase
      .database()
      .ref('/Stations/')
      .on('value', (snapshot) => {
        setTimeout(() => {
          this.stationData = snapshot.val();
          this.allStation$ = this.stationData.data;
          resolve(this.stationData.data)
      }, 100);

      },
      (error)=>{
      })
}

}
