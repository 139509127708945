import { MapConfig } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { MessageConfig } from '@app/shared/constants/constant';
import { ReversePipe } from '@app/shared/pipes/reverse.pipe';
import { CommonService } from '@app/shared/services/common.service';
import { activityUriConfig, NFPURI } from '../constants/trip-config-url';
import { ROUTE_SERVICE_CONSTANTS } from '../constants/trips-constant-config';
import { DownloadFiledFlightPlan, GetTripRouteDetails, GetVendorTripsRouteDetails, GetVendorTripsRouteDetailsApi, RouteDetailApi, RouteObj, SelectFlightPlanByOperator } from '../model/route.model';

@Injectable({
  providedIn: 'root'
})
export class TripRouteService {
  public RouteDetails: GetTripRouteDetails;
  public vendorRouteDetails: GetVendorTripsRouteDetails;
  public storeRoutes: any;
  public LegInformation = new Array();
  public remarks:string;
  constructor(private connectToServer: ServerConnectService,
  private commonService: CommonService, private reverse: ReversePipe) { }
  public async getTripRouteDetails(id: number) {
    return await new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader();
      this.connectToServer.connectToServer(0, activityUriConfig.getClientTripRoute, 'id=' + id, false, false, false
      ).then(async (routeDetails: RouteDetailApi) => {
        await this.commonService.dismissLoader();
        this.RouteDetails = routeDetails?.GetTripRouteDetails;
        if (this.RouteDetails) {
          resolve(this.createRouteArr(routeDetails?.GetTripRouteDetails));
        }
      });
    });
  }
  public async getVendorTripRouteDetails(id: number) {
    return await new Promise((resolve, reject) => {
      this.connectToServer.connectToServer(0, activityUriConfig.getVendorTripRoute, 'tripID=' + id, false, false, false
      ).then((routeDetails: any) => {
        this.RouteDetails = routeDetails.GetVendorTripsRouteDetails;
        resolve(this.createRouteArr(routeDetails.GetVendorTripsRouteDetails));
      });
    });
  }
  public async createRouteArr(routeDetails: GetTripRouteDetails) {
    this.storeRoutes = [];
    if (routeDetails.srdata) {
      this.LegInformation = routeDetails.srdata.Legs;
    }
    routeDetails.Route.forEach((routeData, legIndex) => {
      for (const legId in routeData) {
        if (legId) {
          routeDetails.Route[legIndex][legId].forEach(
            (element, index) => {
              let temparr = "";
              let tempstring = "";
              let start = "";
              let end = "";
              let distance = "";
              const startlattitude = element?.latlngArr[0]?.Latitude;
              const startlongtude = element?.latlngArr[0]?.Longitude;
              const endlattitude =
                element.latlngArr[element.latlngArr.length - 1]
                  .Latitude;
              const endlongtude =
                element.latlngArr[element.latlngArr.length - 1]
                  .Longitude;
              element.latlngArr.forEach((LatLongData) => {
                tempstring =
                  LatLongData.Latitude +
                  "," +
                  LatLongData.Longitude +
                  "|";
                temparr = temparr + tempstring;
              });
              temparr = temparr.slice(0, -1);
              distance = temparr;
              start = startlattitude + "," + startlongtude;
              end = endlattitude + "," + endlongtude;
              element.startMarker = start;
              element.endMarker = end;
              element.polyline = distance;
              element.legId = Number(legId);
              element.googleApiKey = MapConfig.googleAPIKey;
              element.tripStatus = routeDetails.status;
              element.serviceRequested = true;
              element.flightPlanNo = index + 1;
              element.accessType = "vendor";
              element.FlightPlanType =
                element.FlightPlanType == "AvPlat"
                  ? "AvPlat"
                  : "Vendor";
            },
          );

          this.storeRoutes.push(
            routeDetails.Route[legIndex][legId]
          );

          // this.storeRoutes = this.reverse.transform(this.storeRoutes);
        }
      }
    });
    this.RouteDetails.assgnedcrewdetails = routeDetails.assgnedcrewdetails
  }
  public async validateRoute(route, tripId: number, reload?: boolean) {
    const tripID = tripId ? tripId : route.SRID;
    const SRSecID = route.legId ? route.legId : route.legID;
    const respid = route.routeId ? route.routeId : route.RouteID;
    const sector = route.sector ? route.sector : route.Sector;
    const vendorid = route.VendorID ? route.VendorID : route.vendorid;
    const databody = "SRID=" + tripID + "&SRSecID=" + SRSecID + "&respid=" + respid + "&sector=" + sector + "&vendorid=" + vendorid;
    return await new Promise((resolve, reject) => {
      this.commonService
        .showSpinnerLoader(ROUTE_SERVICE_CONSTANTS.validateRoute)
        .then(() => {
          this.connectToServer.connectToServer(0, NFPURI.validateRoute, databody, false, false, false, "api/v1"
          ).then((data) => {
            this.commonService.showMeassgeAlert(MessageConfig.RouteValidated);
            this.commonService.dissmissSpinnerLoader();
            if (reload) {
              this.getTripRouteDetails(tripID);
            }
            resolve(data);
          })
        });
    })
  }
  public async  selectFlightPlan(route, tripID: number, filing: string, action?: string) {
    tripID = tripID ? tripID : route.SRID;
    const SRSecID = route.legId ? route.legId : route.legID;
    const respid = route.routeId ? route.routeId : route.RouteID;
    const sector = route.sector ? route.sector : route.Sector;
    const vendorid = route.VendorID ? route.VendorID : route.vendorid;
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader(ROUTE_SERVICE_CONSTANTS.selectFlightPlan);
      const databody =
        "CrewBriefingAttachmentID=" + route.CrewBriefingAttachmentID + "&SRID=" + tripID + "&SRSecID=" + SRSecID + "&Sector=" +
        sector + "&respID=" + respid + "&autoFile=" + filing + "&vendorid=" + vendorid + "&action=" + action + "&remarks=" + this.remarks;
      this.connectToServer.connectToServer(0, activityUriConfig.selectFlightPlanByOperator, databody, false, false, false).then((response: SelectFlightPlanByOperator) => {
        this.commonService.dissmissSpinnerLoader();
        if (response.SelectFlightPlanByOperator.ValidRoute == "false") {
          this.commonService.showNonSelectableFPAlert(response.SelectFlightPlanByOperator.Reasons);
        } else {
          this.getTripRouteDetails(tripID);
          resolve(response.ResponseStatus);
        }
      });
    });
  }
  public async flightPlanCancellation(cbpId, action?: string) {
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader(action == "delay" ?
        MessageConfig.DelayFlight : action == "change" ?
          MessageConfig.ChangeFlight : MessageConfig.CancelFlight);
      const databody = "CrewBriefingAttachmentID=" + cbpId + "&action=" + action;
      this.connectToServer.connectToServer(0, action == "delay" ?
        NFPURI.delayFlightPlan : action == "change" ?
          NFPURI.changeFlightPlan : NFPURI.cancellFlightPlan, databody, false, false, false, "api/v1").then((response: any) => {
            this.commonService.dissmissSpinnerLoader();
            resolve(response);
          });
    });
  }

  public async downloadFiledFlightPlan(crewBriefingAttachmentID: number) {
    return await new Promise(async (resolve, reject) => {
      this.commonService.showSpinnerLoader(MessageConfig.filedflightPlanDownload);
      const databody = "CrewBriefingAttachmentID=" + crewBriefingAttachmentID;
      this.connectToServer.connectToServer(0, activityUriConfig.DownloadFiledFlightPlan, databody, false, false, false)
        .then(
          (response: DownloadFiledFlightPlan) => {
            this.commonService.dissmissSpinnerLoader();
            resolve(response.DownloadFiledFlightPlan);
          },
          (error) => {
          },
        );
    });
  }
  public reload(routeid: string, type: string, vendorID: string) {
    return new Promise((resolve) => {
      const databody = "routeID=" + routeid + "&type=" + type + "&vendorID=" + vendorID;
      if (this.commonService.roleId == 2) {
        this.commonService.showSpinnerLoader("Updating with latest WX, Notams.");
        this.connectToServer.connectToServer(0, activityUriConfig.refreshDocument, databody, false, false, false)
          .then((tripData: any) => {
            this.commonService.dissmissSpinnerLoader();
            resolve(tripData);
          });
      }
    });
  }
}
