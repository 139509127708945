import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { Passenger_Services_Names } from '@app/shared/enums/common-enums';
import { CommonService } from '@app/shared/services/common.service';

@Pipe({
  name: 'nameService',
})
@Injectable({
  providedIn: 'root',
})
export class NameServicePipe implements PipeTransform {
  constructor(private commonService: CommonService) {}
  public transform(servicename, serviceType, groupServicesBy?: any) {
    if (serviceType == 'airportcharges') {
      if (
        servicename != 'GATerminalCharges' &&
        servicename != 'Followme' &&
        servicename != 'Paxcharges' &&
        servicename != 'PRMCharge' &&
        servicename != 'VIPLounge' &&
        servicename != 'CIPLounge' &&
        servicename != 'CIQExpressFacilitation'
      ) {
        return this.removeName(this.formatstring(servicename));
      } else {
        // sending the hard coded message
        switch (servicename) {
          case 'GATerminalCharges':
            return this.removeName('GA Terminal Charges');
          case 'PRMCharge':
            return this.removeName('PRM Charge');
          case 'VIPLounge':
            return this.removeName('VIP Lounge');
          case 'CIPLounge':
            return this.removeName('CIP Lounge');
          case 'CIQExpressFacilitation':
            return this.removeName('CIQ ExpressFacilitation');
          case 'Followme':
            return this.removeName('Follow Me');
          case 'Paxcharges':
            return 'Pax Charges';
          default:
            return this.removeName(servicename);
        }
      }
    } else if (serviceType == 'handling') {
      if (
        servicename != 'ACU' &&
        servicename != 'GPU' &&
        servicename != 'GENDEC'
      ) {
        // sending the hard coded message
        if (groupServicesBy == 'passengerServices') {
          for (const paxServiceType in Passenger_Services_Names) {
            if (servicename == paxServiceType) {
              return Passenger_Services_Names[paxServiceType];
            }
          }
        } else {
          return this.formatstring(servicename);
        }
      } else {
        return servicename;
      }
    } else if (serviceType == 'fuel') {
      switch (servicename) {
        case 'Peakperiod Surcharge' || 'PeakHour Surcharge':
          if (servicename == 'Peakperiod Surcharge') {
            return 'Peak Period Surcharge';
          } else {
            return 'Peak Hour Surcharge';
          }
        case 'Unitprice':
          return 'Unit Price';
        case 'Hydrantfee':
          return 'Hydrant Fee';
        case 'Hookupfee':
          return 'Hookup Fee';
        case 'Airportfee':
          return 'Airport Fee';
        case 'Concessionfee':
          return 'Concession Fee';
        case 'Infrastructurefee':
          return 'Infrastructure Fee';
        case 'MultiVehiclefee':
          return 'Multi Vehicle Fee';
        default:
          return this.formatstring(servicename);
      }
    } else {
      return servicename;
    }
  }
  /**
   * to send the name by breakinga the captalize letter
   * @param value it the servicename to break with capital letter
   */
  public formatstring(value) {
    if (value != null) {
      const name = value.split(/(?=[A-Z])/).join(' ');
      return name;
    }
  }
  /**
   *
   * @param serviceName name of the service to remove the charges or fee fort the client
   */
  public removeName(serviceName: string) {
    if (this.commonService.roleId == 2) {
      if (serviceName != undefined) {
        return serviceName
          .replace('Charges For', '')
          .replace('Charges', '')
          .replace('Fee', '')
          .replace('Charge', '');
      }
    } else if (this.commonService.roleId == 3) {
      return serviceName;
    }
  }
}
