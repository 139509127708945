import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'QQTime'
})
export class QQTimePipe implements PipeTransform {
  public transform(time: string): string {
    if (!time) return time;

    const lowerCaseTime = time.toLowerCase();
    if (lowerCaseTime === '1 days' || lowerCaseTime === '1 day') {
      return '1 Day';
    } else if (lowerCaseTime === '1 hours' || lowerCaseTime === '1 hour') {
      return '1 Hour';
    } else if (lowerCaseTime === '1 minutes' || lowerCaseTime === '1 minute') {
      return '1 Minute';
    }
    return time;
  }
}