import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize, retryWhen, delay, take } from 'rxjs/operators';
import { UtilService } from './util.service';
import { MessageConfig } from '@app/shared/constants/constant';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  public token: any;
  constructor(private utilService: UtilService) {}
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/x-www-form-urlencoded'),
    });
    request = request.clone({
      setHeaders: {
        'x-api-token': this.utilService.token ? this.utilService.token : '',
      },
    });
    if (this.requestUrlNeedsBase(request)) {
      request = request.clone({
        url: `${this.utilService.actualURL}${request.url}`,
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
      retryWhen(errors =>
        errors.pipe(
          delay(3000),
          take(3)
        )
      ),
      finalize(() => {
        // this.utilService.dismissLoader();
      })
    );
  }

  /**
   * Determines whether to append the base URL for the request.
   */
  private requestUrlNeedsBase(request: HttpRequest<any>): boolean {
    // Check if the URL is a local asset, full URL, or one of the specified API routes
    const isLocalAsset = request.url.startsWith('assets/');
    const isFullUrl = request.url.startsWith('http') || request.url.startsWith('https');
    return !isLocalAsset && !isFullUrl && this.includesArray.some(path => request.url.includes(path));
  }

  /**
   * Error handling method
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMsg: string = '';

    if (error?.error?.status === 'unauthorized' || error.status === 401) {
      this.utilService.showToastCtrlMessage('Unauthorized access, please log in again.');
    }

    if (error.error instanceof ErrorEvent) {
      errorMsg = `Client Error: ${error.error.message}`;
    } else if (!navigator.onLine) {
      this.utilService.showToastCtrlMessage(MessageConfig.wifiIcon + MessageConfig.offlineAlert);
    } else {
      errorMsg = `Server Error: ${error.status}, Message: ${error.message}`;
    }
    this.utilService.dismissLoader();
    return throwError(errorMsg);
  }

  /**
   * List of URL paths to check for base URL appending.
   */
  private includesArray = [
    'assets',
    'velocityweather',
    'v1/ComputeFlight',
    'v1/WeatherNotam',
    'v1/FileFlightPlan/manualFile',
    'v1/FileFlightPlan/cancel',
    'v1/FileFlightPlan/delay',
    'v1/FileFlightPlan/change',
    'v1/ValidateRoute',
    'v1/ProductionAndRemarks',
  ];
}
