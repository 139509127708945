import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private messageSource = new BehaviorSubject<string>('Please wait...');
  public message$ = this.messageSource.asObservable();
  private visibilitySource = new BehaviorSubject<boolean>(false);
  public isVisible$ = this.visibilitySource.asObservable();
  constructor() { }


  public show(message: string) {
    this.messageSource.next(message);
    this.visibilitySource.next(true);
  }

  public hide() {
    this.visibilitySource.next(false);
  }
}
