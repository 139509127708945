import { CommonService } from '@app/shared/services/common.service';
import { EnterDetailsApiService } from './enter-details-api.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  PageHeader,
  Status,
  ButtonText,
  MessageConfig,
} from '@app/shared/constants/constant';
import { TripServices } from '@app/trips/trips-tab/model/trip-service.model';
import { IntoPlane } from '../trips-tab/model/enter-trip-details.model';
import { FileUploadServiceService } from '@app/shared/services/file-upload-service.service';
import { ActivityService } from './activity.service';
import { Activity } from '../trips-tab/model/model.index';
import { Events } from '@app/shared/services/events.service';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { activityUriConfig, NFPURI } from '../constants/trip-config-url';
import { GetATCStripByRouteIDResponse } from '../model/route.model';
import { CBP } from '@app/flight-planning/flight-plan-constant';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class EnterTripDetailsService {
  public servicepageName: string = 'Enter Trip Details';
  public closeEnterTripDetails = new Subject<boolean>();
  public receiveData = new Subject<any>();
  private action: string;
  public filingStatus: any;
  public serviceName: string;
  public activity = new TripServices();
  public srid: number;
  public tripService: any;
  public saveFlag: boolean;
  public toggleSaveBtn: boolean = false;
  public stationTimeofService: any;
  public intoPlaneDetails = new IntoPlane();
  public filename: any;
  public AttachdFile: any;
  public selectedFuelFlowId: any;
  public pageType: string;
  public contact: any;
  public fuelQuantity: any;
  public executeOnlyOnce: boolean = true;
  public arrDepServices: any;
  public handligAranged: boolean = true;
  public editedAttachement: boolean = false;
  constructor(
    public router: Router,
    private enterDetailsApiService: EnterDetailsApiService,
    private commonService: CommonService,
    private fileUploadService: FileUploadServiceService,
    private activityService: ActivityService,
    private events: Events,
    private serverConnect: ServerConnectService,
    private location: Location,
  ) {
    this.toggleButtonLoad();
    this.pageHeader();
  }
  public toggleButtonLoad() {
    const validServices = [
      PageHeader.Fuel,
      PageHeader.SelectFuelOn,
      PageHeader.Handling,
      PageHeader.ArrHandling,
      PageHeader.DepHandling,
      PageHeader.AirportCharges,
      PageHeader.ArrAirportCharges,
      PageHeader.DepAirportCharges,
      PageHeader.SelectHandlingServices,
    ];
    const invalidServices = [PageHeader.Overflight, 'CBP Finalize'];
    invalidServices.indexOf(this.serviceName) == -1 &&
      validServices.includes(this.serviceName)
      ? (this.toggleSaveBtn = true)
      : (this.toggleSaveBtn = false);
  }

  public pageHeader() {
    if (this.serviceName == 'cbpFinalize') {
      this.serviceName = 'Trip Kit';
    }
    switch (this.serviceName) {
      case PageHeader.SelectHandlingServices:
        this.servicepageName = PageHeader.SelectHandlingServices;
        break;
      case PageHeader.Overflight:
        this.servicepageName = PageHeader.Permit;
        break;
      case PageHeader.FlightPlanning:
        this.servicepageName = PageHeader.FlightPlanning;
        break;
      case PageHeader.FileFlightPlan:
        this.servicepageName = this.action
          ? this.getHeaderName()
          : PageHeader.FileFlightPlan;
        break;
      case PageHeader.FileFlightPlanAndGenerateTripKit:
        this.servicepageName = this.action
          ? this.getHeaderName()
          : PageHeader.FileFlightPlanAndGenerateTripKit;
        break;
      case PageHeader.Fuel:
        this.servicepageName = PageHeader.Fuel;
        break;
      case PageHeader.TripKit:
        this.servicepageName = PageHeader.TripKitAttach;
        break;
      case PageHeader.DepHandling:
        if (
          this.activity.previousLegId &&
          this.activity.sector.substring(0, 4) == this.activity.location
        ) {
          this.servicepageName = PageHeader.ArrDepHandling;
        } else {
          this.servicepageName = PageHeader.DepHandling;
        }
        break;
      case PageHeader.ArrHandling:
        if (
          this.activity.previousLegId &&
          this.activity.sector.substring(0, 4) == this.activity.location
        ) {
          this.servicepageName = PageHeader.ArrDepHandling;
        } else {
          this.servicepageName = PageHeader.ArrHandling;
        }
        break;
      case PageHeader.DepAirportCharges:
        if (
          this.activity.previousLegId &&
          this.activity.sector.substring(0, 4) == this.activity.location
        ) {
          this.servicepageName = PageHeader.ArrDepAirportCharges;
        } else {
          this.servicepageName = PageHeader.DepAirportCharges;
        }
        break;
      case PageHeader.ArrAirportCharges:
        if (
          this.activity.previousLegId &&
          this.activity.sector.substring(0, 4) == this.activity.location
        ) {
          this.servicepageName = PageHeader.ArrDepAirportCharges;
        } else {
          this.servicepageName = PageHeader.ArrAirportCharges;
        }
        break;
      case PageHeader.CBPFinaalize:
        this.servicepageName = PageHeader.TripKitFinaalize;
        break;
      case PageHeader.SelectFlightPlan:
        this.servicepageName = PageHeader.SelectFlightPlan;
        break;
      case PageHeader.SelectFuelOn:
        this.servicepageName = PageHeader.SelectFuelOn;
        break;
      default:
        break;
    }
  }
  public getHeaderName() {
    return this.action == 'delay'
      ? PageHeader.DelayFlightPlan
      : this.action == 'change'
        ? PageHeader.ChangeFlightPlan
        : this.serviceName == PageHeader.FileFlightPlanAndGenerateTripKit
          ? PageHeader.FileFlightPlanAndGenerateTripKit
          : PageHeader.FileFlightPlan;
  }
  public serviceDataValidation() {
    return (
      this.servicepageName == PageHeader.OverflightPermits &&
      this.activity.requestType != 'Vendor'
    );
  }
  public requestTypeValidation() {
    return (
      this.toggleSaveBtn &&
      (this.activity.requestType != 'Vendor' ||
        (this.activity.requestType == 'Vendor' &&
          (this.servicepageName == PageHeader.ArrHandling ||
            this.servicepageName == PageHeader.DepHandling)))
    );
  }
  public serviceRequestValidation() {
    return (
      this.servicepageName == PageHeader.FlightPlanning ||
      this.servicepageName == PageHeader.TripKit ||
      this.servicepageName == PageHeader.SelectFlightPlan
    );
  }
  public displayForFileFP() {
    return (this.servicepageName == PageHeader.FileFlightPlan);
  }
  public enbaleSave(flag: boolean, value: any, formStatus: any, attachmentName?: string, File?: string) {
    this.saveFlag = flag;
    switch (this.serviceName) {
      case PageHeader.Overflight:
        break;
      case PageHeader.FlightPlanning:
        break;
      case PageHeader.AirportCharges:
        break;
      case PageHeader.Fuel:
        this.intoPlaneDetails = value;
        this.filename = attachmentName;
        if (
          this.activity.legStatus == Status.Confirmed ||
          (this.activity.legStatus == 'Processing' &&
            this.activity.requestType == 'self' || this.activity.requestType == 'Self')
        ) {
          if (this.activity.requestType == 'Vendor') {
            this.saveFlag = true;
          }
          if (
            this.activity.requestType == 'Self' || this.activity.requestType == 'self' ||
            this.activity.requestType == 'Operator'
          ) {
            this.saveFlag =
              value.name == '' ||
                value.name.length < 2 ||
                value.contactNo == null ||
                (value.departure == 'Arrival' && value.Quantity <= 0) ||
                (value.BayNumber != undefined || value.BayNumber && value.Quantity <= 0) ||
                formStatus == false
                ? false
                : true;
          }
        } else if (this.activity.legStatus != Status.Confirmed) {
          if (attachmentName == 'Attach-FDN' && (File == 'File' || File == 'FILE') ||
            value.Quantity < 0 || value.Quantity == null || value.fdnNumber == null || value.fdnNumber == "" || value.email == "" || value.contactNo == null) {
            this.saveFlag = false;
            this.fuelQuantity = value.Quantity;
            this.checkFuelQuantity();

          } else {
            this.saveFlag = true;
          }
        }
        this.AttachdFile = File;
        break;
      case PageHeader.Handling:
        break;
      case PageHeader.DepHandling:
      case PageHeader.ArrHandling:
      case PageHeader.DepAirportCharges:
      case PageHeader.ArrAirportCharges:
        this.filename = attachmentName;
        this.AttachdFile = File;
        break;
      case PageHeader.SelectFuelOn:
        this.intoPlaneDetails = value;
        this.saveFlag = true;
        break;
      default:
        break;
    }
  }
  public checkFuelQuantity() {

    if (this.fuelQuantity && this.fuelQuantity != 0) {
      this.checkDataAvailable();
    }
    else {
      setTimeout(() => {
        this.checkDataAvailable();
      }, 700);
    }
  }

  public checkDataAvailable() {
    if (this.fuelQuantity == 0) {
      // If the fuel quantity is 0 button will enable
      this.saveFlag = true;
    }
    else {
      // If the fuel quantity is not 0 button will disable
      this.saveFlag = false;
    }
  }
  public close() {
    this.location.back()
  }
  public submit() {
    switch (this.serviceName) {
      case PageHeader.Overflight:
        break;
      case PageHeader.FlightPlanning:
        break;
      case PageHeader.AirportCharges:
      case PageHeader.ArrAirportCharges:
      case PageHeader.DepAirportCharges:
        this.commonService.showConfirmAlert(this.activity.showStatus == 'Inprogress' ? MessageConfig.doneService : this.activity.servicename == 'Select Handling Services' ? MessageConfig.selectService : MessageConfig.arrangeService).then((data) => {
          if (data) {
            if (this.activity.showStatus != 'Upcoming') {
              this.uploadAttachment('airportcharges');
            }
            else {
              this.enterDetailsApiService
                .submitAirportCharges(
                  this.srid,
                  this.activity,
                  this.stationTimeofService
                )
                .then((res) => {
                  if (res == MessageConfig.APISuccessMessage) {
                    this.close();
                  }
                });
            }
          }
        });
        break;
      case PageHeader.Fuel:
        if (
          this.activity.legStatus != Status.Confirmed &&
          this.activity.requestType != 'self' &&
          this.activity.requestType != 'Self' &&
          this.activity.requestType != 'Vendor'
        ) {
          this.submitInprogressFuelDetails();
        } else if (
          this.activity.requestType == 'Operator' ||
          this.activity.requestType == 'Self' ||
          this.activity.requestType == 'self'
        ) {
          if (
            this.activity.legStatus == Status.Processing ||
            this.activity.legStatus == Status.Confirmed
          ) {
            this.submitfuelDetails();
          } else {
            this.submitInprogressFuelDetails();
          }
        } else if (this.activity.requestType == 'Vendor') {
          if (
            this.activity.legStatus == Status.Processing ||
            this.activity.legStatus == Status.Confirmed ||
            this.activity.legStatus == Status.Inprogressstarting
          ) {
            this.intoPlaneDetails.FuelDialCode =
              this.activity.fuelDetails[0].FuelDetailID;
            this.submitfuelOnDetails();
          } else {
            this.submitInprogressFuelDetails();
          }
        } else {
          this.submitfuelDetails();
        }
        break;
      case PageHeader.ArrHandling:
      case PageHeader.DepHandling:
      case PageHeader.SelectHandlingServices:
        const rampServicesByClient =
          this.requestTypeValidation() &&
            (this.activity.requestType == "Vendor" ||
              (this.activity.requestType == "self" &&
                this.activity.servicename == PageHeader.SelectHandlingServices))
            ? true
            : false;
        this.commonService.showConfirmAlert(this.activity.showStatus == 'Inprogress' ? MessageConfig.doneService : this.activity.servicename == 'Select Handling Services' ? MessageConfig.selectService : MessageConfig.arrangeService).then((data) => {
          if (data) {
            this.uploadAttachment('Handling', rampServicesByClient);
          }
        });
        break;
      case PageHeader.SelectFuelOn:
        this.intoPlaneDetails.FuelDialCode =
          this.activity.fuelDetails[0].FuelDetailID;
        this.submitfuelOnDetails();
        break;
      default:
        break;
    }
  }
  public submitBtnText(): string {
    if (this.activity.legStatus == Status.Confirmed) {
      return ButtonText.arrange;
    } else if (
      this.activity.legStatus == Status.Inprogressstarting ||
      this.activity.legStatus == Status.Inprogressfinishing ||
      this.activity.legStatus == Status.Inprogressfinished
    ) {
      return ButtonText.done;
    } else {
      return ButtonText.save;
    }
  }
  public submitfuelDetails() {
    if (this.intoPlaneDetails.contactNo) {
      const formatedContact = this.commonService.contactNumberFormate(
        this.intoPlaneDetails.contactNo
      );
      this.intoPlaneDetails.contactNo = formatedContact.contact;
      this.intoPlaneDetails.FuelDialCode = formatedContact.dialCode;
    }
    this.enterDetailsApiService
      .submitFuelDetails(this.srid, this.intoPlaneDetails, this.activity)
      .then((res) => {
        this.commonService.dismissLoader();
        if (res == MessageConfig.APISuccessMessage) {
          this.close();
        }
      });
  }
  public submitfuelOnDetails() {
    if (this.intoPlaneDetails.contactNo) {
      const formatedContact = this.commonService.contactNumberFormate(
        this.intoPlaneDetails.contactNo
      );
      this.intoPlaneDetails.contactNo = formatedContact.contact;
      this.intoPlaneDetails.FuelDialCode = formatedContact.dialCode;
    }
    this.commonService.dismissLoader();
    this.enterDetailsApiService
      .SubmitFuelOnDetails(this.intoPlaneDetails, this.activity)
      .then((res) => {
        if (res == MessageConfig.APISuccessMessage) {
          this.close();
        }
      });
  }
  public uploadAttachment(serviceName: string, specificServiceByClient?: any) {
    const legId = this.activity.previousSector && this.activity.previousTimeOfService == 'arrival' ? this.activity.previousLegId : this.activity.legId;
    const fileNameof = this.filename ? this.filename.split('.').pop() : '';
    let filename = `${serviceName}_${this.srid}_${legId}.${fileNameof}`;
    let counter = 0;
    if (this.activity?.DepartureAttachments?.length > 0) {
      counter = this.activity?.DepartureAttachments?.length;
      filename = filename.split('.').shift();
      filename = `${filename}_${counter}.${fileNameof}`;
    }
    else if (this.activity?.ArrivalAttachments?.length > 0) {
      counter = this.activity?.ArrivalAttachments?.length;
      filename = filename.split('.').shift();
      filename = `${filename}_${counter}.${fileNameof}`;
    }
    else {
      filename = filename
    }
    this.arrDepServices = this.activity.onTimeOfService == 'Arr ' || this.activity.previousTimeOfService == 'arrival' ? 'arrival' : 'departure';
    const storePath = `${serviceName}Attachments/${this.arrDepServices}/${this.commonService.uid}/${this.srid}/${legId}/${filename}`;
    const submitFunction = (res: string) => {
      if (res === 'Success') {
        this.close();
      }
    };
    if (this.activity.showStatus != 'Upcoming' &&
      (serviceName === 'Handling' && this.filename !== `Attach-${serviceName}`) ||
      (serviceName === 'airportcharges' && !this.filename?.startsWith(`Attach-${serviceName}`))
    ) {
      this.fileUploadService?.FirebaseBucketUpload(storePath, this.AttachdFile)
        .then((data: string) => {
          this.activity.AttachmentName = filename;
          this.submitServiceFunction(serviceName, specificServiceByClient).then(submitFunction);
        });
    } else {
      this.submitServiceFunction(serviceName, specificServiceByClient).then(submitFunction);
    }
  }
  public submitServiceFunction(serviceName: string, specificService?: any) {
    let submitPromise;
    if (serviceName === 'Handling') {
      submitPromise = this.enterDetailsApiService.submitHandling(
        this.srid,
        this.activity,
        this.stationTimeofService,
        specificService
      );
    } else if (serviceName === 'airportcharges') {
      submitPromise = this.enterDetailsApiService.submitAirportCharges(
        this.srid,
        this.activity,
        this.stationTimeofService
      );
    }

    return submitPromise;
  }
  public submitInprogressFuelDetails() {
    this.saveFlag = false;
    const timeNow = new Date().getTime();
    const fileNameof = this.filename.split('.').pop();
    let filename = `FDN_${this.srid}_${this.activity.legId}.${fileNameof}`;
    let counter = 0;
    this.commonService.showSpinnerLoader();
    if (this.activity.serviceDetails.length > 0) {
      counter = counter + this.activity.serviceDetails.length
      // Counter to keep track of file uploads
      filename = filename.split('.').shift()
      filename = `${filename}_${counter}`
      filename = filename + '.' + fileNameof
    } else {
      filename = filename
    }
    const storePath = `${this.commonService.uid}/fueldata/${this.srid}/${this.activity.legId}/${this.activity.location}/${filename}`
    this.filename != 'Attach-FDN' ? this.fileUploadService
      .FirebaseBucketUpload(
        storePath,
        this.AttachdFile
      )
      .then((data: string) => {
        this.activity.AttachmentName = filename;
        this.enterDetailsApiService
          .submitInprogressFuelDetails(
            this.srid,
            this.intoPlaneDetails,
            this.activity,
            this.filename
          )
          .then((res) => {
            if (res == MessageConfig.APISuccessMessage) {
              this.activityService
                .fetchTripActivity(this.srid)
                .then((data2: Activity) => {
                  this.activity = data2.services.filter(
                    (item: TripServices) => {
                      return (
                        item.servicename == 'Fuel' &&
                        this.selectedFuelFlowId == item.FlowID
                      );
                    }
                  )[0];
                  this.intoPlaneDetails.Quantity = null;
                  this.events.publish('resetAttachment');
                  this.saveFlag = true;
                });
            }
            this.close();
          });
      }) : this.enterDetailsApiService
        .submitInprogressFuelDetails(
          this.srid,
          this.intoPlaneDetails,
          this.activity
        )
        .then((res) => {
          if (res == MessageConfig.APISuccessMessage) {
            this.activityService
              .fetchTripActivity(this.srid)
              .then((data2: Activity) => {
                this.activity = data2.services.filter(
                  (item: TripServices) => {
                    return (
                      item.servicename == 'Fuel' &&
                      this.selectedFuelFlowId == item.FlowID
                    );
                  }
                )[0];
                this.intoPlaneDetails.Quantity = null;
                this.events.publish('resetAttachment');
                this.saveFlag = true;
              });
          }
          this.close();
        });
  }
  public getATCRouteByLegID(id: number, type: string) {
    const databody = type + "=" + id;
    return new Promise((resolve, reject) => {
      this.serverConnect.connectToServer(0, activityUriConfig.GetATCStripByRouteID, databody, false, false, false).then((data: GetATCStripByRouteIDResponse) => {
        resolve(data.GetATCStripByRouteID);
      })
    })
  }
  public async submitATCRoute(cbp: CBP) {
    return await new Promise((resolve, reject) => {
      const databody = "SRID=" + cbp.SRID + "&sector=" + cbp.sector + "&attName=" + cbp.ATCRoute + "&filename=" + encodeURIComponent(cbp.FilePath) +
        "&SRSecID=" + cbp.legid + "&alt1=" + cbp.alt1 + "&alt2=" + cbp.alt2 + "&modifiedData=" + JSON.stringify(cbp.modifiedData);
      this.serverConnect.connectToServer(0, NFPURI.uploadATCRoute, databody, false, false, false, "api/v1").then((response: any) => {
        if (!response.ProcessUploadCB) {
        }
        resolve(response.ProcessUploadCBP);
      });
    });
  }
  public async uploadTripKitToFirebaseStorage(tripKitFilePath: string, tripKit: any, sector: any) {
    this.commonService.showSpinnerLoader(MessageConfig.AttachTripKit);
    const timeNow = new Date().getTime();
    const path = "crewdatacbp/" + this.commonService.uid + "/" + sector + "/" + tripKitFilePath
    this.fileUploadService.FirebaseBucketUpload(path, tripKit).then((data: string) => {
      this.commonService.dissmissSpinnerLoader();
      return data;
    });
  }
  public finalizeCBP(srid, cbpAttID, filename, sector, SRSecID?: any) {
    const databody = "SRID=" + srid + "&CrewBriefingAttachmentID=" + cbpAttID + "&CBPFilename=" +
      encodeURIComponent(filename) +
      "&finalise=1" + "&Sector=" + sector + "&SRSecID=" + SRSecID;
    return new Promise((resolve) => {
      this.serverConnect.connectToServer(0, activityUriConfig.FinalizeCBP, databody, false, false, false).then((response: any) => {
        resolve(response.FinalizeCBP);
      });
    });
  }
}
