import { Injectable } from '@angular/core';
import { UtilService } from '@app/core/services/util.service';
import firebase from 'firebase';
import { Observable, Subject } from 'rxjs/Rx';
import { BehaviorSubject, of } from 'rxjs';
import { CommonService } from '@app/shared/services/common.service';
import {
  Card,
  PayOut,
} from '@app/passbook/wallet-balance/add-money/add-money.component';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { ChatDetails } from '../models/shared.model';
import { ProfileData } from '@app/right-menu/models/right-menu.model';
import { MessageConfig } from '../constants/constant';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
declare var cordova: any;
@Injectable({
  providedIn: 'root',
})
export class FirebaseDataService {
  public fuelUnit: Observable<any[]>;
  public FueltypeArr: Observable<any[]>;
  public fueltype = [];
  public currencyNames: Observable<any[]>;
  public currencyPrices: Observable<any[]>;
  public userProfile: Observable<any[]>;
  public notificationArr: any;
  public $NotificationsRef: BehaviorSubject<any> = new BehaviorSubject([]);
  public countryList: Observable<any[]>;
  public chatDetailsArr = new Subject();
  public fessFBArr: Observable<any[]>;
  public surchargeFBArr: any;
  public handlingSurcharges: any;
  public handlingOptionalService: any
  public handlingService: any
  public mcdCategory: any;
  public fLPVendorList = [];
  public countryCode: any;
  public referrence: any;
  public activeInstance: any;
  public unreadNotificationRef: any;
  public newNoificationCount= new BehaviorSubject<boolean>(false);
  public CharterNotificationsUnread: BehaviorSubject<any> = new BehaviorSubject([]);
  public operatorUid;
public finalData = [];
public vendorList: { [key: string]: string } = {};
  constructor(
    private commonservice: CommonService,
    private authSessionService: AuthSessionService,
    private utilService: UtilService,
    private fileOpener: FileOpener ,private transfer: FileTransfer
  ) { }
  public callfirebaseListData() {
    this.getFuelType();
    this.getFuelUnit();
    this.getCurrencyNames();
    this.getCurrencyPrices();
    this.getCountryList();
    this.getfessFBArr();
    this.getSurchargesArr();
    this.getAvPlatFLPVendorId();
    this.getallCountry();
    this.getHandlingSurcharges();
    this.getHandlingOptionServices();
    this.getHandlingServices();
    this.getExistingLogos();

  }
  public getallCountry() {
    firebase
      .database()
      .ref("/allCountries/")
      .on("value", (snapshot) => {
        if (snapshot.val() != null) {
          this.commonservice.countryCode = snapshot.val();
        } else {
          this.commonservice.countryCode = Observable.of([]);
        }
      });
  }
  public getFileFormPath(path) {
    if (this.utilService.isBrowser()) {
      this.dowloadforWeb(String(path));
    } else {
      this.downloadfordevice(String(path));
    }
  }
  public getFuelType() {
    firebase.database().ref("/FuelType/").on("value", (snapshot) => {
      this.FueltypeArr = snapshot.val();
      this.fueltype = [];
      for (const data in this.FueltypeArr) {
        if (this.FueltypeArr[data].flag == 1) {
          this.fueltype.push(this.FueltypeArr[data].Typename);
        }
      }
    });
  }
  public getAllUserProfile() {
    firebase
      .database()
      .ref('/UserProfile/')
      .on('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.userProfile = snapshot.val();
        } else {
          this.userProfile = Observable.of([]);
        }
      });
  }
  public getAvPlatFLPVendorId() {
    firebase
      .database()
      .ref("/nfp_flight_plan_vendorid/")
      .on("value", (snapshot) => {
        if (snapshot.val() != null) {
          this.fLPVendorList = snapshot.val();
        } else {
          this.fLPVendorList = [];
        }
      });
  }
  public getFuelUnit() {
    firebase
      .database()
      .ref('/FuelUnit/')
      .on('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.fuelUnit = snapshot.val();
        } else {
          this.fuelUnit = Observable.of([]);
        }
      });
  }
  public getCurrencyNames() {
    firebase
      .database()
      .ref('/CurrencyNames/')
      .on('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.currencyNames = snapshot.val();
        } else {
          this.currencyNames = Observable.of([]);
        }
      });
  }

  public setCardToVerify(cardDetails: Card) {
    return this.uploadtoFirebase(cardDetails);
  }
  public storeData(token: string, data: any): void {
    firebase
    .database()
    .ref(`/PublicFDN/${token}`)
    .push(data);
  }
  public uploadtoFirebase(cardDetails) {
    return firebase
      .database()
      .ref('/UserCardPayVerification/' + this.commonservice.uid + '/')
      .push(cardDetails);
  }
  public checkStatusOfPayment(key) {
    firebase
      .database()
      .ref('/UserCardPayVerification/' + this.commonservice.uid + '/' + key)
      .on('value', (snapshot) => {
        if (snapshot.val() != null) {
          const data = snapshot.val();
          if (data.Status == 'Failed') {
            this.commonservice.callingAlert(data.Message);
          }
        }
      });
  }

  public putRequestData(payOutDetails: PayOut) {
    return this.uploadData(payOutDetails);
  }
  public uploadData(amount) {
    return firebase
      .database()
      .ref('/PayoutRequests/' + this.commonservice.uid + '/')
      .push(amount);
  }
  public getCurrencyPrices() {
    firebase
      .database()
      .ref('/CurrencyPrices/')
      .on('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.currencyPrices = snapshot.val();
        } else {
          this.currencyPrices = Observable.of([]);
        }
      });
  }

  public getfessFBArr() {
    firebase
      .database()
      .ref("/FuelFees/")
      .on("value", (snapshot) => {
        this.fessFBArr = snapshot.val();
      });
  }
  public getSurchargesArr() {
    firebase
      .database()
      .ref("/FuelSurcharges/")
      .on("value", (snapshot) => {
        this.surchargeFBArr = snapshot.val();
      });
  }
  public getHandlingSurcharges() {
    firebase
      .database()
      .ref("/HandlingSurcharge/")
      .on("value", (snapshot) => {
        this.handlingSurcharges = snapshot.val();
      });
  }
  public getHandlingOptionServices() {
    firebase
      .database()
      .ref("/HandlingOptionalServices/")
      .on("value", (snapshot) => {
        this.handlingOptionalService = snapshot.val();
      });
  }
  public getHandlingServices() {
    firebase
      .database()
      .ref("/HandlingServices/")
      .on("value", (snapshot) => {
        this.handlingService = snapshot.val();
      });
  }
  public getMcdCategory() {
    firebase.database().ref("/FuelMarketDataCategory/")
      .on("value", (snapshot) => {
        if (snapshot.val() != null) {
          this.mcdCategory = snapshot.val();
        } else {
          this.mcdCategory = [];
        }
      });
  }
  public getJetCodesByMarketCode(MCD: string) {
    return new Promise((resolve) => {
      firebase
        .database()
        .ref("/JetCodes/")
        .orderByChild("MDC")
        .equalTo(MCD)
        .on("value", (snapshot) => {
          if (snapshot.val() != null) {
            const jetcodes = snapshot.val();
            const result = [];
            for (const key in jetcodes) {
              if (key) {
                result.push(jetcodes[key]);
              }
            }
            resolve(result);
          } else {
            resolve([]);
          }
        });
    });
  }
  public getDownloadUrl(path: string) {
    const Fstorage = firebase.storage();
    const storageref = Fstorage.ref(path);
    return storageref.getDownloadURL();
  }
  public downloadfileFrombucket(path: string, downloadfilename: string, altPath?) {
    const storageref = firebase.storage().ref();
    storageref
      .child(path + '/' + downloadfilename)
      .getDownloadURL()
      .then(
        (url: string) => {
          this.commonservice.dissmissSpinnerLoader();
          this.openDoc(url, downloadfilename);
        },
        (err) => {
          if (err.code === 'storage/object-not-found' && altPath) {
            storageref
            .child(altPath + '/' + downloadfilename)
            .getDownloadURL()
            .then(
              (url1: string) => {
                this.commonservice.dissmissSpinnerLoader();
                this.openDoc(url1, downloadfilename);
              }).catch((error)=>{
              })
          }
          this.commonservice.dissmissSpinnerLoader();
        }
      );
  }
  public openDoc(url: string, filename?: string) {
    let storageDirectory: any;
    // checking the mobileweb or its on desktop
    if (this.utilService.isBrowser()) {
      window.open(url);
    } else {
      if (Capacitor.getPlatform() === "ios") {
        storageDirectory = cordova.file.documentsDirectory;
      } else if (this.utilService.isAndroid()) {
        storageDirectory = cordova.file.externalDataDirectory;
      }
      const fileTransfer: FileTransferObject = this.transfer.create();
      fileTransfer
        .download(url, storageDirectory + filename)
        .then(
          (entry) => {
            const fileArray = filename.split(".");
            const extension = fileArray[fileArray.length - 1];
            let mimeType = null;
            this.getMIMEtype(extension).then((res: any) => {
              mimeType = res;
              this.fileOpener.open(storageDirectory + filename, mimeType).then(() => {
                this.commonservice.dissmissSpinnerLoader();
              })
                .catch((e) => {
                  this.commonservice.dissmissSpinnerLoader();
                });
            }).catch((error) => {
              this.commonservice.dissmissSpinnerLoader();
            });
          },
          (error) => { }
        );
    }
  }
  public getMIMEtype(extn) {
    return new Promise((resolve, reject) => {
      const ext = extn.toLowerCase();
      const mimeType = {
        docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        doc: "application/msword",
        pdf: "application/pdf",
        jpg: "image/jpg",
        jpeg: "image/jpeg",
        bmp: "image/bmp",
        png: "image/png",
        xls: "application/vnd.ms-excel",
        xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        rtf: "application/rtf",
        ppt: "application/vnd.ms-powerpoint",
        pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        csv: "text/csv",
        txt: "text/plain",
      };
      return resolve(mimeType[ext]);
    });
  }
  public getCountryList() {
    firebase
      .database()
      .ref('/aircraft_registercountry/')
      .on('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.countryList = snapshot.val();
        } else {
          this.countryList = Observable.of([]);
        }
      });
  }
  public dowloadforWeb(url): void {
    this.getDownloadUrl(url).then((returnUrl) => {
      window.open(returnUrl);
      const fileArray = url.split('.');
      this.commonservice.dismissLoader();
    }).catch((err) => {
      this.commonservice.dismissLoader();
    });
  }
  public downloadfordevice(url: string) {
    const urlBreak: any = url.split('/');
    const filename: any = urlBreak.pop();
    const beforePop = urlBreak;
    const join = beforePop.toString();
    const path = join.split(',').join('/');
    this.downloadfileFrombucket(path, filename);
  }
  public async getValueByPathFromFirebase(path: string) {
    await firebase
      .database()
      .ref(
        path,
      )
      .once("value", (snapshot) => {
        if (snapshot.val() != null) {
          return snapshot.val();
        } else {
          return null;
        }
      });
  }
  public sendSelectedTripNotificationRef(id) {
    this.authSessionService.userSessionSubject.subscribe((res) => {
      if (res) {
        const refString = '/Notification/' + res.firebase_uid + '/' + id;
        firebase.database().ref(refString).orderByChild('senddate').on("value", (snapshot) => {
          if (snapshot.val() != null && snapshot.val()) {
            this.$NotificationsRef.next(snapshot.val());
          }
          else {
            this.$NotificationsRef.next(null);
          }
        });
      }
    });
  }
  public getTripNotification() {
    this.commonservice.showSpinnerLoader()
    this.unreadNotificationRef = firebase
      .database()
      .ref(
        "/Notification/" +
          this.commonservice.uid
      );
    this.unreadNotificationRef.on(
      "value",
      (snapshot) => {
        if (snapshot.val() != null) {
          snapshot.forEach((childSnapshot)=>{
            const childData = childSnapshot.val();
            for(const tripId in childData){
                if(childData[tripId]?.view=='unread')
                {
                  this.finalData.push(childData[tripId])
                }
            }
            this.CharterNotificationsUnread.next(this.finalData)
          })
          this.newNoificationCount.next(true);
        } else {
          this.newNoificationCount.next(false);
          this.CharterNotificationsUnread.next(null);
        }
      },
      (error) => {
        this.commonservice.dismissLoader()
        // this.tripNotification(tripId);
      },
    );
  }

  public checkChatReffExist(fromChild: string, toChild: string, tripID: number) {
    const ref = "/Chats/" + fromChild + "," + toChild + "/" + tripID;
    return new Promise((resolve, reject) => {
      firebase.database().ref(ref).once("value", (snapshot3) => {
        if (snapshot3.exists()) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  public async fetchChatDetails(chatRefference: string) {
    return new Promise(async (resolve, reject) => {
      firebase.database().ref(chatRefference).on("value", async (snapshot4) => {
        if (snapshot4.val()) {
          this.chatDetailsArr.next(Object.keys(snapshot4.val()).map(
            (key) => snapshot4.val()[key],
          ));
          resolve(Object.keys(snapshot4.val()).map(
            (key) => snapshot4.val()[key],
          ));
        } else {
          resolve(new Array<ChatDetails>());
        }
      });
    })
  }
  public getUserInfoByUID(uid: string) {
    const userRef = firebase.database().ref("/UserProfile/" + uid);
    return new Promise((resolve, reject) => {
      userRef.on("value", (snapshot5) => {
        resolve(new ProfileData(snapshot5.val()));
      });
    })
  }

  public sendMessage(chatRefference: string, message: string, toChatId: string) {
    this.commonservice.getUTCTime();
    return new Promise((resolve, reject) => {
      const chatDetails = new ChatDetails(
        message,
        this.commonservice.NowTime,
        String(this.commonservice.uid),
        toChatId,
        false,
        "text",
      );
      firebase.database().ref(chatRefference).push(Object.assign({}, chatDetails));
      resolve(true);
    })
  }
  public supportLink() {
    return new Promise((resolve) => {
      const supportLink = firebase.database().ref("/SupportURL/");
      supportLink.on("value", (snapshot) => {
        resolve(snapshot.val());
      });
    });
  }
  public async markNotificationAsRead(id: string) {
    this.authSessionService.userSessionSubject.subscribe((res) => {
      if (res) {
        const refString = '/Notification/' + res.firebase_uid + '/' + id;
        firebase.database().ref(refString).orderByChild('senddate').limitToFirst(20).on("value", (snapshot) => {
          if (snapshot.val() != null && snapshot.val()) {
            for (const key in snapshot.val()) {
              if (key) {
                const readReff = '/Notification/' + res.firebase_uid + '/' + id + '/' + key + '/view/';
                firebase.database().ref(readReff).set("read");
              }
            }
          }
        });
      }
    });
  }
  public async updateTripWiseActivity(count: number, tripID: number) {
    return firebase
      .database()
      .ref("/Activity/" + this.commonservice.uid + "/" + tripID + "/")
      .update({ Count: count });
  }
  public createTripStatusEvent(
    UUID: any,
    tripID: number,
    TripStatus: string) {
    if (UUID == undefined || tripID == undefined || TripStatus == undefined) {
      return;
    }
    switch (TripStatus) {
      case "Pending":
        TripStatus = "invalid";
        break;
      case "Declined":
        TripStatus = "invalid";
        break;
      case "In Progress":
      case "Inprogress":
        TripStatus = "Active";
        break;
      case "Completed":
        TripStatus = "Closed";
        break;
      case "Upcoming":
        TripStatus = "Confirmed";
        break;
      default:
        TripStatus = TripStatus;
    }
    this.referrence = firebase.database().ref("/ClientTrips/" + UUID + "/" + tripID + "/TripStatus/");
    if (this.referrence) {
      this.activeInstance = this.referrence.on("value", (snapshot) => {
        let firebaseTripStatus = snapshot.val();
        if (TripStatus != null && firebaseTripStatus != null && TripStatus != firebaseTripStatus) {
          switch (firebaseTripStatus) {
            case "invalid":
              firebaseTripStatus = "Pending";
              break;
            case "Active":
              firebaseTripStatus = "In Progress";
              break;
            case "Closed":
              firebaseTripStatus = "Completed";
              break;
            case "Confirmed":
              firebaseTripStatus = "Upcoming";
              break;
          }
          this.commonservice.showToastCtrl("Trip Id " + tripID + " moved to Trips-" + firebaseTripStatus, "bottom", true);
        }
      });
    }
  }

  // Firebase storage delete function
  public storageFileDelete(path,fileName){
    const ref = firebase.storage().ref(path);
    const childRef = ref.child(fileName);
    childRef.delete().then((res) => {
       // File deleted successfully
    })
    .catch((error) => {
    });
  }
  public getExistingLogos() {
    firebase
      .database()
      .ref("/vendorLogs/")
      .on("value", (snapshot) => {
        this.vendorList = snapshot.val();
      });
  }
    public getLogosComon(term:string): Observable<{vendorNames: string[], fullMatch: string | null}>{
      if (!term.trim()) {
        return of({vendorNames: Object.keys(this.vendorList), fullMatch: null});
      }
      const lowerCaseTerm = term.toLowerCase();
      const vendorNames = Object.keys(this.vendorList);
      const fullMatch = vendorNames.find(name => name.toLowerCase() === lowerCaseTerm) || null;
      return of({vendorNames, fullMatch});
    }
}
