
import * as moment from "moment";
import { MessageConfig } from '@app/shared/constants/constant';
export class FuelData {
    public selectedtaxList = [];
    public Oprationalnotes = "";
    public testPattern: any;
    public selectableCurr: any;
    public storeCurrencyList: any;
    public storeListOfOperators: any;
    public platformType: any;
    public currencyNames: any;
    public flyingTo = ["Domestic", "Foreign"];
    public fuelapplied = ["Domestic", "International"];
    public registered = ["yes", "no"];
    public operation = [
        "Commercial",
        "Cargo",
        "Charter",
        "Private",
        "Ambulance",
    ];
    public flighttype = ["NS", "S", "G", "M", "X"];
    public stationArr: any;
    public cost = "";
    public stopType = ["Techstop", "Transitstop", "Stayover"];
    public rateCardOptions:string[];
    public rateCardType:string = "1";
    public getClients:GetAllClients[];
    public sendListOfSelectedClients:GetAllClients[] = [];
    public validityFrom = "";
    public validityTo = "";
    public leadtimeType = "Days";
    public leadtime = "";
    public revesioncost = "";
    public shortpermitcost = "";
    public currency: any;
    public minYear = new Date().toISOString();
    public minYearto: any = 2021;
    public validfrom: any;
    public validto = moment().endOf('year').format('YYYY-MM-DD');
    public listSelected = "";
    public curcost = "";
    public currevesioncost = "";
    public curshortpermitcost = "";
    public SlotPermitID: any;
    public LandingPermitID: any;
    public vendorType: string = "Fueler";
    public specificGravity: any;
    public fuelType: string[] = ["JetA1", "AVGAS"];
    public fuelUnit: string = "KG";
    public priceType: string = "PAP";
    public priceindex = "PLATTS";
    public priceindextype = "HIGH NWE CIFJET KERO";
    public priceindextypevalid = "Inday";
    public mcdCode: string;
    public priceval: string;
    public fueling = ["nozzle", "pressure"];
    public intoPlaneName: string;
    public intoPlanecontact: any;
    public intoPlaneEmail: string;
    public alterIntoPlaneEmail = [] = [];
    public fuelerName: string;
    public fuelerContact: any;
    public fuelerEmail: string;
    public alterfuelerEmail = [] = [];
    public papprice: string;
    public intoplancallingcode: any;
    public fuelercallingcode: string;
    public unitDiffprice: number;
    public diffprice: number;
    public throughputfee: number;
    public fuelChargeID?: string;
    public validFromTo: any;
    public surcharges: any;
    public photoUrl: any;
    // public resellerPhotoUrl:any;
    public fuelLogo: any;
    public vendorDescription = "";
    public showSelect:boolean = false;
    public setHandlerName:any;
    constructor(fuelReceivedData?: FuelBasicPrice, public currencyService?: any, public commonService?: any) {
        if (fuelReceivedData) {
            this.priceType = fuelReceivedData?.DifferntialType;
            this.stationArr = fuelReceivedData?.AirportName;
            this.fuelapplied = fuelReceivedData?.AircraftRegisterType;
            this.registered = fuelReceivedData?.ICAO;
            this.operation = fuelReceivedData?.OperationType;
            this.flighttype = fuelReceivedData?.FlightType;
            this.fuelUnit = fuelReceivedData?.UnitType;
            this.validfrom = fuelReceivedData?.ValidFrom;
            this.validto = fuelReceivedData?.ValidTo;
            this.validFromTo = this.commonService.datePickerFormat(fuelReceivedData.ValidFrom, fuelReceivedData.ValidTo);
            this.currency = fuelReceivedData?.Currency;
            this.fueling = fuelReceivedData?.TypeofFueling;
            this.selectableCurr = fuelReceivedData?.SelectedCurrency;
            this.fuelType = fuelReceivedData?.FuelType;
            this.specificGravity = fuelReceivedData?.SpecificGravity ? fuelReceivedData?.SpecificGravity : this.specificGravity;
            this.stopType = fuelReceivedData?.StopType;
            this.vendorType = fuelReceivedData?.TypeofVendor;
            this.intoPlaneName = fuelReceivedData?.FuelIntoPlanDetails[0]?.IntoplanName;
            this.intoPlaneEmail = (fuelReceivedData?.FuelIntoPlanDetails[0]?.IntoplanEmail) ? fuelReceivedData.FuelIntoPlanDetails[0].IntoplanEmail : this.intoPlaneEmail;
            this.alterIntoPlaneEmail = (fuelReceivedData?.FuelIntoPlanDetails[0]?.AlterIntoPlaneEmail) ? fuelReceivedData.FuelIntoPlanDetails[0].AlterIntoPlaneEmail: this.alterIntoPlaneEmail;
            this.intoplancallingcode = fuelReceivedData?.FuelIntoPlanDetails[0]?.Intoplancallingcode;
            if (fuelReceivedData?.FuelIntoPlanDetails[0]?.IntoplanContactNo) {
                this.intoPlanecontact = this.commonService.getContactNumberFormate(
                    fuelReceivedData?.FuelIntoPlanDetails[0]?.Intoplancallingcode,
                    fuelReceivedData?.FuelIntoPlanDetails[0]?.IntoplanContactNo,
                );
            }
            this.fuelerName = fuelReceivedData?.FuelIntoPlanDetails[0]?.ResellerName;
            this.fuelerEmail = (fuelReceivedData?.FuelIntoPlanDetails[0]?.ResellerEmail) ? fuelReceivedData.FuelIntoPlanDetails[0].ResellerEmail: this.fuelerEmail;
            this.alterfuelerEmail = (fuelReceivedData?.FuelIntoPlanDetails[0]?.AlterfuelerEmail) ? fuelReceivedData.FuelIntoPlanDetails[0].AlterfuelerEmail : (this.alterfuelerEmail.length > 0 ? this.commonService.tranformMailsToLowerCase(this.alterfuelerEmail): this.alterfuelerEmail);
            this.fuelercallingcode = fuelReceivedData?.FuelIntoPlanDetails[0]?.Resellercallingcode;
            if (fuelReceivedData.FuelIntoPlanDetails[0].ResellerContactNo) {
                this.fuelerContact = this.commonService.getContactNumberFormate(
                    fuelReceivedData?.FuelIntoPlanDetails[0]?.Resellercallingcode,
                    fuelReceivedData?.FuelIntoPlanDetails[0]?.ResellerContactNo,
                );
            }
            this.fuelChargeID = fuelReceivedData.FuelChargeID;
            this.flyingTo = this.fuelChargeID ? [] : this.flyingTo;
            fuelReceivedData.GoingOn.forEach((element) => {
                this.flyingTo.push(element);
            })
            this.mcdCode = fuelReceivedData.MCDCode;
            this.throughputfee = fuelReceivedData.ThroughputFee;
            this.diffprice = fuelReceivedData.DifferentialPrice;
            this.unitDiffprice = fuelReceivedData.UnitDiffPrice;
            this.selectedtaxList = fuelReceivedData.Taxes;
            fuelReceivedData.FuelSurchargesPrices.fuelinfo.forEach(element => {
                this.Oprationalnotes = element.Oprationalnotes;
            });
            this.vendorDescription = fuelReceivedData?.vendordesc ? fuelReceivedData.vendordesc: this.vendorDescription;
            this.photoUrl = fuelReceivedData?.FuelIntoPlanDetails[0]?.FuelLogo;
            if (this.photoUrl == "undefined") {
                this.photoUrl = MessageConfig.NoImageAvailable;
            }
            this.rateCardType = fuelReceivedData.RateCardType ? fuelReceivedData.RateCardType : this.rateCardType;
            this.sendListOfSelectedClients = fuelReceivedData.RecievedOperators ? fuelReceivedData.RecievedOperators : this.sendListOfSelectedClients;
        }
    }
}

export class SurchargeFormData {
    public surchargeApplieson: string;
    public surchagesprice: string;
    public surchargeuplift: string;
    public surchargeFlighttype: string
    public surchargeWeekend1: string;
    public surchargeWeekend2: string;
    public holidaysurchargename: string;
    public holidaySurchargeStartdate: string;
    public holidaySurchargeEnddate = moment().endOf("year").format();
    public peakperiodsurchargename: string;
    public peakperiodSurchargeStartdate: any;
    public peakperiodSurchargeEnddate = moment().endOf("year").format();
    public peakhoursurchargename: string;
    public peakhourSurchargeStartdate: string;
    public peakhourSurchargeEnddate: string;
    public surchargeOutsideAirporttimeStart: string;
    public surchargeOutsideAirporttimeEnd: string;
    public surchargeOutsideOfficetimeStart: string;
    public surchargeOutsideOfficetimeEnd: string;
    public surchargeNighttimeStart: any;
    public surchargeNighttimeEnd: any;
}
export class SendFuelData {
    public closeFlag: string;
    public saveValue: string;
}
export interface Currency {
    currencyId: string;
    name: string;
}

export interface Fuelinfo {
    FuelinfoSetupId: string;
    AirportOperationalHoursStartTime: string;
    AirportOperationalHoursEndTime: string;
    WeekendDay1: string;
    WeekendDay2: string;
    PeakPeriodStartDate: string;
    PeakPeriodEndDate: string;
    PeakHourStartTime: string;
    PeakHourEndTime: string;
    NightSurchargeStartTime: string;
    NightSurchargeEndTime: string;
    OfficeHoursStartTime: string;
    OfficeHoursEndTime: string;
    FuelChargeID: string;
    flag: string;
    Oprationalnotes: string;
}

export interface FuelSurchargesPrices {
    fuelsurchargesprices: any[];
    fuelinfo: Fuelinfo[];
    fuelchargeid: string;
}

export interface FuelIntoPlanDetail {
    FADID: string;
    FuelChargeID: string;
    ResellerOrNot: string;
    ResellerName: string;
    ResellerEmail: string;
    AlterfuelerEmail: any;
    ResellerContactNo: string;
    Resellercallingcode: string;
    IntoplanName: string;
    IntoplanEmail: string;
    AlterIntoPlaneEmail: any;
    IntoplanContactNo: string;
    IntoplanName1?: any;
    IntoplanEmail1?: any;
    IntoplanContactNo1?: any;
    Intoplancallingcode: any;
    Intoplancallingcode1?: any;
    FuelLogo: any;
}

export interface FuelFeePrice {
    FFID: string;
    FeeName: string;
    flag: string;
    FFPID: string;
    FuelChargeID: string;
    Priceval: number;
    ApplicableCondition: string;
    CID: string;
    UnitID: string;
    TripType: string[];
    PriceType: string;
    FuelCapacity: string;
    FuelCapacityUnit: string;
}

export interface FuelBasicPrice {
    FuelChargeID: string;
    AirportID: string;
    VendorID: string;
    TypeofVendor: string;
    SpecificGravity: number;
    StopType: string[];
    GoingOn: string[];
    SelectedCurrency: string;
    Currency: Currency;
    UnitType: string;
    TypeofFueling: string[];
    FuelType: string[];
    ICAO: string[];
    AircraftRegisterType: any;
    OperationType: string[];
    FlightType: string[];
    ThroughputFee: number;
    ThroughputunitType: string;
    ThroughputCurrency: string;
    PriceIndex: string;
    PriceIndexType: string;
    MCDCode?: any;
    DifferntialType: string;
    FixedPrice: string;
    FixedPriceunitType: string;
    FixedPriceCurrency: string;
    DifferentialPrice: number;
    UnitDiffPrice: number;
    DifferentialPriceunitType: string;
    DifferentialPriceCurrency: string;
    TradedDays: string;
    FuelCost: string;
    FuelcostunitType: string;
    FuelcostCurrency: string;
    ValidFrom: string;
    ValidTo: string;
    CreatedDate: string;
    flag: string;
    SurchargeFlag: string;
    FeeFlag: string;
    comment?: any;
    AirportName: string;
    CityName: string;
    Country: string;
    IATACode: string;
    FAAIdent: string;
    ICAOCode: string;
    Latitude: string;
    Longitude: string;
    Elevation: string;
    UTC: string;
    DST: string;
    timezone: string;
    verified: string;
    searchIndexString: string;
    UserID: string;
    VendorServicesCat: string;
    VendorHandlerval: string;
    VendorName: string;
    VendorInvoiceAddressline1: string;
    VendorInvoiceAddressline2: string;
    VendorInvoiceAddressline3: string;
    VendorContactNo: string;
    VendorOperationContactName: string;
    VendorOperationContactNo: string;
    VendorOperationContactEmailID: string;
    VendorAccDeptContactName: string;
    VendorAccDeptContactNo: string;
    VendorAccDeptContactEmailID: string;
    vendordesc: string;
    PAN_No: string;
    Service_TaxNo: string;
    CompanyName: string;
    Description: string;
    BankName: string;
    Branch: string;
    CurrentACNo: string;
    IFSCCode: string;
    Verified: string;
    logo: string;
    Timezone: string;
    currencyCode: string;
    name: string;
    CompanyDetails: string;
    PaymentType: string;
    unitType: string;
    FrequencyString: string;
    FrequencyUnit: string;
    Fax: string;
    SupplierType: string;
    TollFreeNumber: string;
    OperationalStartFrom: string;
    OperationalEndTo: string;
    Pincode: string;
    State: string;
    Documentpath: string;
    filenametitle: string;
    vendorcallingCodes: string;
    vendorOpscallingCodes: string;
    vendoraccountcallingCodes: string;
    DT_RowId: string;
    PriceIndexTypeValid: string;
    FuelSurchargesPrices: FuelSurchargesPrices;
    FuelIntoPlanDetails: FuelIntoPlanDetail[];
    FuelFeePrices: FuelFeePrice[];
    Taxes: any[];
    RateCardType: string;
    operator: string[] | string;
    RecievedOperators: GetAllClients[];
}

export interface FuelBasicPriceResponse {
    ResponseCode: string;
    APIName: string;
    FuelBasicPrice: FuelBasicPrice;
    ResponseStatus: string;
}
export interface GetAllClients {
    ClientID: string;
    ClientName: string;
    EmailID: string;
    logo: string;
    rateCardId: string;
  }
  export interface GellAllClientResponse {
    ResponseCode: string;
    APIName: string;
    RateCardType:string[];
    GetAllClients:GetAllClients[];
    ResponseStatus: string;
  }