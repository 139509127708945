import { Communication, ThirdPartyDisbursement, SupervisionCharges, AdminFee, SetupFee } from './../navigation/navigation-model';
import { PermitData } from './../ovf-permits/permit-model';
import { BehaviorSubject } from 'rxjs';
import { ApiTypes, MessageConfig } from '@app/shared/constants/constant';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { queryParams } from './../../shared/utils/query-params';
import { CommonService } from '@app/shared/services/common.service';
import {
  ServiceProviderUriConfig,
  SetupUriConfig,
} from './../setup-url-constant';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { SlotData } from '../slot/slot-landing-model';
import { CrewbriefingData } from '../flight-planning/flight-planning-model';
import { TaxesData } from '../setup.types';
import { GellAllClientResponse, GetAllClients } from '../fuel/fuel.model';
import { FuelSetupService } from '../fuel/fuel-setup.service';
@Injectable({
  providedIn: 'root',
})
export class SetupService {
  public httpRequestType = ApiTypes;
  public saveUserSessionSubject: any = new BehaviorSubject<any>([]);
  public serviceName: string;
  public storeAllClients:GetAllClients[] = [];
  public flagForServiceProvidersApiFetch: boolean = true;
  constructor(
    public https: HttpClient,
    private commonService: CommonService,
    private serverConnectService: ServerConnectService,
    private authSessionService: AuthSessionService,
    public fuelSetupService: FuelSetupService
  ) {
    this.authSessionService.userSessionSubject.subscribe((data) => {
      this.saveUserSessionSubject.next(data);
    });
  }

  public verifyPin(pin: number) {
    const databody = queryParams({
      SecurePin: pin,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          SetupUriConfig.VerifyPin,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }
  public sendlogin() {
    const databody = queryParams({
      deviceToken: this.commonService.deviceToken,
      registrationType: this.commonService.registrationType,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          SetupUriConfig.SignInURL,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          this.authSessionService.userSessionSubject.next(res.APILogin);
          this.saveUserSessionSubject.next(res.APILogin);
          resolve(res);
        });
    });
  }
  public setPin() {
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          SetupUriConfig.SetUserPin,
          '',
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  public serviceNameResult(serviceName) {
    switch (serviceName) {
      case 'Apt Fees & Permits':
        this.serviceName = 'AptFeesPermits';
        break;
      case 'Fuel':
        this.serviceName = 'Fuel';
        break;
      case 'Handling':
        this.serviceName = 'Handling';
        break;
      case 'Flight Planning':
        this.serviceName = 'FlightPlanning';
        break;
      case 'Nav Fees & Permits':
        this.serviceName = 'OvfPermits';
        break;
      default:
        break;
    }
    return this.serviceName;
  }

  public GetServiceVendors(serviceName) {
    this.serviceNameResult(serviceName);
    const databody = queryParams({
      UserEmailID: this.commonService.userName,
      UserPass: this.commonService.password,
      ServiceName: this.serviceName,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.GetServiceVendors,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  public GetServiceVendorsSelected() {
    const databody =
      'UserEmailID=' +
      this.commonService.userName +
      '&UserPass=' +
      this.commonService.password +
      '&enct=1';
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.getServiceProviderSelected,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  public saveServiceVendorsBy(serviceName, selectedVendorsBy, vendorId?) {
    this.serviceNameResult(serviceName);
    const databody = queryParams({
      Servicename: this.serviceName,
      value: selectedVendorsBy,
      vendorId,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.saveServiceProviderVendorsBy,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }
  public getVendorTaxes(lastid, serviceId: number) {
    const databody = queryParams({
      serviceid: serviceId,
    });
    return new Promise<TaxesData[]>((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.GetTaxList,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.TaxData);
        });
    });
  }

  public saveTax(taxdata: TaxesData, currency) {
    const sendObj = {
      UserEmailID: this.commonService.userName,
      UserPass: this.commonService.password,
      enct: 1,
      name: taxdata.Title,
      rate: taxdata.Rate,
      currrate: taxdata.currRate,
      type: taxdata.Type,
      Forallservices: "particular",
      servicearr: JSON.stringify(taxdata.AssociatedWith),
      taxID: taxdata.TaxID,
      SelectedCurrency: currency.currencyId,
      Currency: JSON.stringify(currency),
    }
    const databody = queryParams(sendObj);
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.ProcessSaveTax,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }



  public deleteTax(id: string) {
    const databody = queryParams({
      id,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.DeleteTaxData,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.ResponseStatus);
        });
    });
  }
  public getServicesStation(serviceName: string) {
    const databody = queryParams({
      lastminSRID: 0,
      Limit: 0
    });
    let url: string;
    let object: string;
    switch (serviceName) {
      case 'handling':
        url = ServiceProviderUriConfig.getHandlingStationList;
        object = 'GetGroundHandlingCharges';
        break;
      case 'fuel':
        url = ServiceProviderUriConfig.getFuelStationList;
        object = 'ViewFuelData';
        break;
      case 'airport charges':
        url = ServiceProviderUriConfig.getAirportChargesStationList;
        object = 'AirportCharges';
        break;
      case 'landing':
        url = ServiceProviderUriConfig.getLandingStationList;
        object = 'GetLandingCharges';
        break;
      case 'slot':
        url = ServiceProviderUriConfig.getSlotStationList;
        object = 'GetSlotCharges';
        break;
      case 'flight-planning':
        url = ServiceProviderUriConfig.getFlightPlanningStationList;
        object = 'GetCrewBriefingCharges';
        break;
      case 'ovf-permits':
        url = ServiceProviderUriConfig.getOverFlightStationList;
        object = 'OverflightPermitCharges';
        break;
      case 'navigation':
        url = ServiceProviderUriConfig.getNavigationStationList;
        object = 'GetNewNavigationCharges';
        break;
    }
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          url,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          if (serviceName == 'navigation') {
            resolve(res[object].SelectedCountries);
          } else {
            resolve(res[object]);
          }
        });
    });
  }

  public getServicesPriceList(serviceName: string, stationId: string) {
    let databody: any;
    if (serviceName == 'fuel') {
      databody = queryParams({
        airportID: stationId,
        Limit: 0
      });
    } else {
      databody = queryParams({
        AirportID: stationId,
      });
    }

    let url: string;
    let object: string;
    switch (serviceName) {
      case 'handling':
        url = ServiceProviderUriConfig.getHandlingPriceList;
        object = 'GetGroundHandlingByAirportID';
        break;
      case 'fuel':
        url = ServiceProviderUriConfig.getFuelPriceList;
        object = 'ViewFuelDataByAirportID';
        break;
      case 'airport charges':
        url = ServiceProviderUriConfig.getAirportPriceList;
        object = 'AirportChargesByAirportId';
        break;
      case 'landing':
        url = ServiceProviderUriConfig.getLandingPriceList;
        object = 'GetLandingChargesAirportID';
        break;
      case 'slot':
        url = ServiceProviderUriConfig.getSlotPriceList;
        object = 'GetSlotChargesByAirportID';
        break;
      case 'flight-planning':
        url = ServiceProviderUriConfig.getFlightPlanningPriceList;
        object = 'GetCrewBriefingByAirportID';
        break;
      case 'ovf-permits':
        url = ServiceProviderUriConfig.getOverfligtPermitPriceList;
        object = 'GetOverflightPermitChargesByAirportID';
        break;
      case 'navigation':
        url = ServiceProviderUriConfig.getNavigationPriceList;
        object = 'GetNavigationChargesByCountryID';
        break;
    }
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          url,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res[object]);
        });
    });
  }

  public updateProvideServiceFlag(
    serviceName: string,
    stationId: string,
    flag: string
  ) {
    const databody = queryParams({
      id: stationId,
      CountryID:stationId,
      flag,
      type: 'pre',
    });
    let url: string;
    let object: string;
    switch (serviceName) {
      case 'handling':
        url = ServiceProviderUriConfig.UpdateFlagOfHandling;
        object = 'typeString';
        break;
      case 'fuel':
        url = ServiceProviderUriConfig.UpdateFlagOfFuel;
        object = 'typeString';
        break;
      case 'airport charges':
        url = ServiceProviderUriConfig.UpdateFlagOfAirport;
        object = 'typeString';
        break;
      case 'landing':
        url = ServiceProviderUriConfig.UpdateFlagOfLanding;
        object = 'typeString';
        break;
      case 'slot':
        url = ServiceProviderUriConfig.UpdateFlagOfSlot;
        object = 'typeString';
        break;
      case 'flight-planning':
        url = ServiceProviderUriConfig.UpdateFlagOfFlightPlanning;
        object = 'typeString';
        break;
      case 'ovf-permits':
        url = ServiceProviderUriConfig.UpdateFlagOfOverFlight;
        object = 'typeString';
        break;
      case 'navigation':
        url = ServiceProviderUriConfig.DeleteNewNavigationChargesByCountryID;
        object = 'typeString';
        break;
    }
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          url,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res[object]);
        });
    });
  }

  public slotSavePrice(slotData: SlotData, isUpdate: boolean) {
    const sendArr = Array();
    slotData?.selectedtaxList?.forEach((element) => {
      sendArr.push(element.TaxID);
    });
    const jsonArray = JSON.stringify(sendArr);
    const sendObject = {
      SlotPermitID: slotData.SlotPermitID,
      AirportID: isUpdate ? slotData.stationArr : JSON.stringify(slotData.stationArr),
      RegisterType: isUpdate ? slotData.fuelapplied : JSON.stringify(slotData.fuelapplied),
      ICAO: JSON.stringify(slotData.registered),
      OperationType: JSON.stringify(slotData.operation),
      flighttype: JSON.stringify(slotData.flighttype),
      Oprationalnotes: slotData.Oprationalnotes,
      slotpermitCost: slotData.cost,
      curslotpermitCost: slotData.curcost,
      Agentrequired: slotData.agent,
      Currency: JSON.stringify(slotData.currency),
      slotvalidaityfrom: slotData.validfrom,
      slotvalidaityto: slotData.validto,
      usepermitvalidityfrom: slotData.validityFrom,
      usepermitvalidityfromunit: slotData.validityTypefrom,
      usepermitvalidityto: slotData.validityTo,
      usepermitvaliditytounit: slotData.validityTypeto,
      Leadtime: slotData.leadtime,
      leadtimeunit: slotData.leadtimeType,
      SlotRevisionCost: slotData.revesioncost,
      curSlotRevisionCost: slotData.currevesioncost,
      shortnoticepermitcost: slotData.shortpermitcost,
      curshortnoticepermitcost: slotData.curshortpermitcost,
      selectedtaxList: jsonArray,
      SelectedCurrency: slotData.currency.currencyId,
    }
    if (!isUpdate) {
      delete sendObject.SlotPermitID;
    }
    const databody = queryParams(sendObject);
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.AddSlotPrice,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }
  public landingSavePrice(slotData: SlotData, isUpdate: boolean) {
    const sendArr = Array();
    slotData?.selectedtaxList?.forEach((element) => {
      sendArr.push(element.TaxID);
    });
    const jsonArray = JSON.stringify(sendArr);
    const sendObject = {
      landingID: slotData.LandingPermitID,
      AirportID: isUpdate ? slotData.stationArr : JSON.stringify(slotData.stationArr),
      RegisterType: isUpdate ? slotData.fuelapplied : JSON.stringify(slotData.fuelapplied),
      ICAO: JSON.stringify(slotData.registered),
      OperationType: JSON.stringify(slotData.operation),
      flighttype: JSON.stringify(slotData.flighttype),
      Oprationalnotes: slotData.Oprationalnotes,
      LandingCost: slotData.cost,
      curLandingCost: slotData.curcost,
      Agentrequired: slotData.agent,
      Currency: JSON.stringify(slotData.currency),
      permitvalidityfrom: slotData.validfrom,
      permitvalidityto: slotData.validto,
      usepermitvalidityfrom: slotData.validityFrom,
      permitvalidityunitfrom: slotData.validityTypefrom,
      usepermitvalidityto: slotData.validityTo,
      permitvalidityunitto: slotData.validityTypeto,
      Leadtime: slotData.leadtime,
      leadtimeunit: slotData.leadtimeType,
      LandingRevisionCost: slotData.revesioncost,
      curLandingRevisionCost: slotData.currevesioncost,
      shortnoticepermitcost: slotData.shortpermitcost,
      curshortnoticepermitcost: slotData.curshortpermitcost,
      selectedtaxList: jsonArray,
      SelectedCurrency: slotData.currency.currencyId,
    }
    if (!isUpdate) {
      delete sendObject.landingID;
    }
    const databody = queryParams(sendObject);
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.AddLandingPrice,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }
  public permitSavePrice(permitData: PermitData, isUpdate: boolean) {
    const sendArr = Array();
    permitData.selectedtaxList.forEach((element) => {
      sendArr.push(element.TaxID);
    });
    const jsonArray = JSON.stringify(sendArr);
    const sendObject = {
      OverFlightChargesID: permitData.OverFlightChargesID,
      CountryID: isUpdate ? permitData.stiotionIdArr : JSON.stringify(permitData.stiotionIdArr),
      registertype: isUpdate ? permitData.fuelapplied : JSON.stringify(permitData.fuelapplied),
      OverFlightCharge: permitData.cost,
      curOverFlightCharge: permitData.curcost,
      Authorizedagent: permitData.agent,
      pricevaliddatefrom: permitData.validfrom,
      pricevaliddateto: permitData.validto,
      Currency: JSON.stringify(permitData.currency),
      usevalidityfrom: permitData.validityFrom,
      usevalidityfromunit: permitData.validityTypefrom,
      usevalidityto: permitData.validityTo,
      usevaliditytounit: permitData.validityTypeto,
      Leadtime: permitData.leadtime,
      leadtimeunit: permitData.leadtimeType,
      revisionscost: permitData.revesioncost,
      currevisionscost: permitData.currevesioncost,
      shortnoticepermitcost: permitData.shortpermitcost,
      curshortnoticepermitcost: permitData.curshortpermitcost,
      selectedtaxList: jsonArray,
      operation: JSON.stringify(permitData.operation),
      flighttype: JSON.stringify(permitData.flighttype),
      SelectedCurrency: permitData.currency.currencyId,
      TypeOfVendor: permitData.vendortype,
      ICAO: JSON.stringify(permitData.registered),
      Oprationalnotes: permitData.Oprationalnotes,
    }
    if (!isUpdate) {
      delete sendObject.OverFlightChargesID;
    }
    const databody = queryParams(sendObject);
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.AddPermitPrice,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }
  public CrewbriefingSavePrice(crewbriefingData: CrewbriefingData, isUpdate: boolean, registertype: any) {
    const sendArr = Array();
    crewbriefingData.selectedtaxList.forEach((element) => {
      sendArr.push(element.TaxID);
    });
    const jsonArray = JSON.stringify(sendArr);
    const sendObject = {
      registertype: crewbriefingData.registertype,
      ETOPSCost: crewbriefingData.ETOPSCost,
      currETOPSCost: crewbriefingData.curETOPSCost,
      NonETOPSCost: crewbriefingData.NonETOPSCost,
      currNonETOPSCost: crewbriefingData.curNonETOPSCost,
      runwayanalysis: crewbriefingData.runwayanalysis,
      weightandbalance: crewbriefingData.weightandbalance,
      PriceValidFrom: crewbriefingData.PriceValidFrom,
      PriceValidTo: crewbriefingData.PriceValidTo,
      CrewBriefingChargesID: crewbriefingData.CrewBriefingChargesID,
      selectedtaxList: jsonArray,
      currunwayanalysis: crewbriefingData.currunwayanalysis,
      currweightandbalance: crewbriefingData.curweightandbalance,
      Currency: JSON.stringify(crewbriefingData.currency),
      Oprationalnotes: crewbriefingData.Oprationalnotes,
      SelectedCurrency: crewbriefingData.currency.currencyId,
      StationArr: JSON.stringify(crewbriefingData.stationArr),
      TypeofVendor: crewbriefingData.venderType,

      isapplicable: crewbriefingData.isapplicable,
      CrewBriefingCost: crewbriefingData.CrewBriefingCost,
      currCrewBriefingCost: crewbriefingData.curCrewBriefingCost,
    }
    if (registertype == "International") {
      delete sendObject.isapplicable;
      delete sendObject.CrewBriefingCost;
      delete sendObject.currCrewBriefingCost;
    }
    if (registertype == "Domestic") {
      delete sendObject.ETOPSCost;
      delete sendObject.currETOPSCost;
      delete sendObject.NonETOPSCost;
      delete sendObject.currNonETOPSCost;
    }
    const databody = queryParams(sendObject);
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.AddCrewBriefingPrice,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  public stationAddedToastMessage(type: string, newly: boolean) {
    const message =
      String(type == "station" || "Country" ? "Rate card" : "Country") +
      String(newly ? " added successfully." : " updated successfully.");
    this.commonService.showToastCtrl(message);
  }

  public async showCurrencyCovertionAlert(response: any) {
    if (response == "Please enter correct prices") {
      this.commonService.showMeassgeAlert(MessageConfig.CurrencyConversionAlert);
    } else {
      return true;
    }
  }

  public fetchNavigationCharges() {
    const databody = "";
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.GetNewNavigationCharges,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }


  public fetchNavigationChargesByCountryID(countryId, navigationId) {
    const databody = queryParams({
      CountryID: countryId,
      NavigationID: navigationId,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.GetNewNavigationChargesByCountryID,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  public updateNavigationBasicDetails(basicSetup, surcharges, navigationId) {
    // const data = basicSetup.
    const databody = queryParams({
      BasicSetup: JSON.stringify(basicSetup),
      Surcharges: JSON.stringify(surcharges),
      navigationId,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.UpdateNavigationCharges,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  public saveNavigationBasicDetails(basicSetup, surcharges) {
    const databody = queryParams({
      BasicSetup: JSON.stringify(basicSetup),
      Surcharges: JSON.stringify(surcharges),
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.SaveNewNavigationCharges,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  public navSurchargesData() {
    const surchargesValues: any = [];
    const communication = new Communication(
      null,
      "Communication",
      "",
      "",
      "",
      "",
      "",
      false,
      "Fixed",
    );
    const thirdPartyDisbursement = new ThirdPartyDisbursement(
      null,
      "Third Party Disbursement",
      "",
      "",
      "",
      "",
      "",
      false,
      "Fixed",
    );
    const supervisionCharges = new SupervisionCharges(
      null,
      "Supervision Charges",
      "",
      "",
      "",
      "",
      "",
      false,
      "Fixed",
    );
    const adminFee = new AdminFee(
      null,
      "AdminFee",
      "",
      "",
      "",
      "",
      "",
      false,
      "Fixed",
    );
    const setupFee = new SetupFee(
      null,
      "SetupFee",
      "",
      "",
      "",
      "",
      "",
      false,
      "Fixed",
    );
    surchargesValues.push(
      communication,
      thirdPartyDisbursement,
      supervisionCharges,
      adminFee,
      setupFee,
    );
    return new Promise((resolve) => {
      resolve(surchargesValues);
    });
  }

  public deleteSetupDetails(Id, Flag, Type) {
    const databody = queryParams({
      id: Id,
      flag: Flag,
      type: Type
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.DeleteSetup,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.ResponseStatus);
        });
    });
  }

  public deleteNavCountryByID(countryId, navigationId, Flag) {
    const databody = queryParams({
      CountryID: countryId,
      NavigationID: navigationId,
      flag: Flag,
    });

    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          ServiceProviderUriConfig.DeleteNewNavigationChargesByCountryID,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.DeleteNewNavigationChargesByCountryID);
        }).catch((err) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.deleteNavCountryByID(countryId, navigationId,Flag);
              return;
            }
          });
        });
    });
  }
  public stationInsert(Data) {
    const databody = queryParams({
      AirportName:Data.AirportName,
      CityName : Data.CityName,
      Country : Data.Country,
      IATACode :Data?.IATACode ? Data?.IATACode?.toUpperCase():'',
      FAAIdent : Data.FAAIdent,
      ICAOCode : Data?.ICAOCode?.toUpperCase(),
      Latitude : Data.Latitude,
      Longitude : Data.Longitude,
      Elevation : Data.Elevation,
      UTC : Data.UTC,
      DST :Data?.DST?.toUpperCase(),
      flag : Data.flag,
      timezone: Data.timezone,
      verified :Data.verified,
      searchIndexString : Data.searchIndexString,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          SetupUriConfig.AddAirport,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }
  public rateCardOptions() {
    return new Promise((resolve, reject) => {
      const payload = '';
      this.serverConnectService
      .connectToServer(
        this.httpRequestType.POST,
        ServiceProviderUriConfig.GetRateCardOptions,
        payload,
        false,
        false,
        false
      )
      .then(async (res: GellAllClientResponse) => {
        this.fuelSetupService.rateCardOption = res.RateCardType;
        this.fuelSetupService.getClientData = res.GetAllClients;
        this.storeAllClients = res.GetAllClients;
        resolve(res);
      }).catch((error)=>{
        this.commonService.dissmissSpinnerLoader();
        reject(error);
      });
    })
  }
  public displayOperatorList(listOfStation: any) {
    if(listOfStation && Array.isArray(listOfStation.operatordetails)) {
      const dtRowId = listOfStation.DT_RowId; // Get the current station's DT_RowId
    // Filter operators based on the DT_RowId (if applicable)
    const filteredOperators = listOfStation.operatordetails.filter(
      (operator) => operator.ClientName !== '' && listOfStation.DT_RowId === dtRowId
    );
  // Return the first operator's ClientName if available
  const displayList: string = filteredOperators.length
  ? filteredOperators[0].ClientName
  : '';
return displayList;
} else if(typeof listOfStation.operatordetails === 'string' && listOfStation.operatordetails.trim() !== '') {
  const rateCardId = listOfStation.DT_RowId;
  listOfStation.operatordetails = this.storeAllClients;
  const allOperatorList = listOfStation.operatordetails.filter(
    (allOperators) => allOperators.ClientName !== '' && listOfStation.DT_RowId === rateCardId
  );
  const displayAllList: string = allOperatorList.length ? allOperatorList[0].ClientName : '';
  return displayAllList;
}
return '';
  }
}
