import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthRoutingModule } from '@app/authentication/authentication-routing.module';
import { StatusCodeModule } from '@app/status-code/status-code.module';

import { AuthGuard } from './core/guards/auth.guard';
import { RedirectGuard } from './core/guards/redirect-guard.guard';
import { RolesGuard } from './core/guards/roles.guard';
import { ModulePermissions } from './core/models/core.model';

const routes: Routes = [
  // Default redirect guard
  { path: '', pathMatch: 'full', children: [], canActivate: [AuthGuard, RedirectGuard] },

  // Individual routes
  { path: 'flight-plan/:id', redirectTo: 'flight-plan', pathMatch: 'full' },
  { path: 'estimates', loadChildren: () => import('@app/estimates/estimates.module').then(m => m.EstimatesPageModule), canActivate: [AuthGuard] },
  {
    path: 'invoices',
    loadChildren: () => import('@app/invoices/invoices.module').then(m => m.InvoicesModule),
    canActivate: [AuthGuard, RolesGuard],
    data: { moduleName: ModulePermissions.Invoices, access: 'U' },
  },
  { path: 'setup', loadChildren: () => import('@app/setup/setup.module').then(m => m.SetupPageModule), canActivate: [AuthGuard] },
  {
    path: 'quick-qoutes',
    loadChildren: () => import('@app/quick-qoutes/quick-qoutes.module').then(m => m.QuickQoutesPageModule),
    canActivate: [AuthGuard, RolesGuard],
    data: { moduleName: ModulePermissions.QuickQuotes, access: 'U' },
  },
  {
    path: 'wallet',
    loadChildren: () => import('@app/passbook/passbook.module').then(m => m.PassbookBalanceModule),
    canActivate: [AuthGuard, RolesGuard],
    data: { moduleName: ModulePermissions.Passbook, access: 'U' },
  },
  { path: 'trips', loadChildren: () => import('@app/trips/trips.module').then(m => m.TripsPageModule),canActivate: [AuthGuard]},
  {
    path: 'requests',
    loadChildren: () => import('@app/requests/requests.module').then(m => m.RequestsPageModule),
    canActivate: [AuthGuard, RolesGuard],
    data: { moduleName: ModulePermissions.Requests, access: 'U' },
  },
  { path: 'profile-setup', loadChildren: () => import('@app/profile-setup/profile-setup.module').then(m => m.ProfileSetupPageModule), canActivate: [AuthGuard] },
  { path: 'flight-plan/:id', loadChildren: () => import('@app/flight-planning/flight-plan.module').then(m => m.FlightPlanModule), canActivate: [AuthGuard] },
  { path: 'route-plan', loadChildren: () => import('./flight-planning/route-plan/route-plan.module').then(m => m.RoutePlanPageModule), canActivate: [AuthGuard] },
  {
    path: 'charter',
    loadChildren: () => import('./charter/features/charter-route/charter-route.module').then(m => m.CharterRouteModule),
    canActivate: [AuthGuard, RolesGuard],
    data: { moduleName: ModulePermissions.Passbook, access: 'U' },
  },
  {
    path: 'schedule-vendor',
    loadChildren: () => import('@app/trips/schedule-creation/schedule-vendor/schedule-vendor-routing.module').then(m => m.ScheduleVendorPageRoutingModule),
  },
  { path: 'schedule-creation', loadChildren: () => import('@app/trips/schedule-creation/schedule-creation.module').then(m => m.ScheduleCreationModule) },
  { path: 'save-estimate', loadChildren: () => import('./charter/features/save-estimate/save-estimate.module').then(m => m.SaveEstimatePageModule) },
  { path: 'flight-plan-listing', loadChildren: () => import('./flightplans/feutures/flight-plans-route/flight-plan.module').then(m => m.FlightPlanModule), canActivate: [AuthGuard] },
  { path: 'fight-plan-info', loadChildren: () => import('./flightplans/feutures/fight-plan-info/fight-plan-info.module').then(m => m.FightPlanInfoPageModule) },
  {
    path: 'fuel-delivery-note/:token',
    loadChildren: () => import('./fuel-delivery-note/fuel-delivery-note.module').then( m => m.FuelDeliveryNotePageModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule), canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '404', pathMatch: 'full' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    AuthRoutingModule,
    StatusCodeModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
