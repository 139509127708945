import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  constructor(private http: HttpClient) {
    this.initializeAppListeners();

   }

  // Initialize Capacitor app state listeners
  private initializeAppListeners() {
    App.addListener('appStateChange', (state) => {
      if (state.isActive) {
        // App has returned to the foreground, retry pending requests
        this.retryPendingApiCalls();
      }
    });
  }

  // Retry any pending API calls that were stored when the app was minimized
  private retryPendingApiCalls() {
    const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests') || '[]');

    // Retry each stored request
    pendingRequests.forEach((req) => {
      const httpRequest = new HttpRequest(req.method, req.url, req.body, {
        headers: req.headers,
      });

      this.http.request(httpRequest).subscribe(
        (response) => {
          // Handle successful response
        },
        (error) => {
          // Handle error, retry logic can be added here if needed
        }
      );
    });

    // Clear pending requests after retry
    localStorage.removeItem('pendingRequests');
  }
}
