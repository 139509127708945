import * as timezones from 'moment-timezone';
export class StationDetails {
  public name: string;
  public timeZone: string;
  public time: any;
  public utcTime: any;
  public Latitude?:any;
  public Longitude?:any;
  constructor(data: any) {
    this.name = data.name;
    this.timeZone = data.timeZone;
    this.time = this.convertAirporttime(
      this.timeZone,
      this.makeTripTime(60, data.time)
    );
    this.utcTime =
      this.makeTripTime(60, data.time)
     this.Latitude = data?.Latitude
     this.Longitude = data?.Longitude;
  }
  public convertAirporttime(timezone, time) {
    if (timezone != '' && timezone) {
      return timezones.tz(time, timezone).format();
    } else {
      return timezones(time).format();
    }
  }
  public makeTripTime(addtime, time) {
    return timezones.utc(time).add(addtime, 'minutes').format();
  }
}
export class CreateTrip {
  public from: any;
  public to: any;
  public fromStation: string = '';
  public toStation: string = '';
  public returnDate: string = '';
  public starttimeZone?: any;
  public endtimeZone: string = '';
  public depart?: string;
  public timeType: string = 'true';
  public departStartDate?: any;
  public returnStartDate: any;
  public departEndDate: any;

  public storeLegs: {
    BLKTIME: any;
    arrivalTimeInUTC: any;
    fromStation: string;
    toStation: string;
    dateTime: any;
    pax?:any;
    starttimeZone?: string;
    endtimeZone?: string;
    departStartDate?: any;
    departEndDate?: any;
    departDate?: any;
    Latitude?: number;
    Longitude?: number;
    Distance?:any;
    Blktimeinhours?:any;
    depDateTimeInLT?:any;
    arrivalDateTimeInLT?:any;
    minDate?:any;
  }[] = [];

  public pax: string = '0';
  public legAddComplete: boolean = false;
  public legId: number = 0;
  public flightType: string = 'NS';
  public flightRule: string = 'IFR';
  public operationType: string = 'charter';
  public departDate?: any;

  // Outbound leg information
  public Distance: string | null = null;
  public BLKTIME: any;
  public Blktimeinhours: number | null = null;

  // Return leg information
  public returnBLKTIME: any;  // For return leg block time
  public returnBlktimeinhours: number | null = null;  // For return leg block time in hours
  public returnDistance: string | null = null;  // For return leg distance
  public returnArrivalTimeInUTC: string | null = null;  // For return leg arrival time in UTC
  public returnDepTimeInUTC: string | null = null;  // For return leg departure time in UTC

  // Geolocation Information
  public Latitude?: number;
  public Longitude?: number;

  // Editable Options
  public baseStationEditable: boolean = false;

  // Timing Information
  public minUTCtime: string = '';
  public minLTtime: string = '';
  public tripTime: string = '';
  public arrivalTimeInUTC: string | null = null;
  public depTimeInUTC: string | null = null;

  // Added local time for arrival and departure
  public arrivalDateTimeInLT: string | null = null;
  public depDateTimeInLT: string | null = null;
  public departStartDateReturnDate: string | null = null;
  public tripType: string = 'oneWay';
}
export class LegInfoDeatils {
  public from: any;
  public to: any;
  public legAddComplete: boolean;
  public timeZone: string;
  public endtimeZone: string;
  public departDate: any;
  public departEndDate: any;
  public pax: string;
  public legId: number;
  public flightType: string;
  public flightRule: string;
  public operationType: string;
  public baseStationEditable: boolean;
  public blkTime: any;
  public blkTimeInHrs: any;
  public timeType: string;
  public distance: string;
  public legOnHighlight: boolean;
  public addLeg: boolean;
  public arrivalTime: string;
  public arrivalDateTimeInUTC: string;
  public depDateTimeInUTC: string;
  public arrivalDateTimeInLT: string;
  public depDateTimeInLT: string;
  constructor(data?: CreateTrip, legOnHighlight?: boolean, addLeg?: boolean,) {
    if (data) {
      this.from = {
        name: data.fromStation,
        timeZone: data.starttimeZone
      },
        this.to = {
          name: data.toStation,
          timeZone: data.endtimeZone
        },
        this.departDate = data.departDate;
      this.pax = data.pax;
      this.legId = data.legId += 1;
      this.flightType = data.flightType;
      this.flightRule = data.flightRule;
      this.operationType = data.operationType;
      this.baseStationEditable = data.baseStationEditable;
      this.blkTime = data.BLKTIME;
      this.blkTimeInHrs = data.Blktimeinhours;
      this.distance = data.Distance;
      this.legOnHighlight = legOnHighlight ? legOnHighlight: this.legOnHighlight;
      this.addLeg = addLeg ? addLeg: this.addLeg;
      this.arrivalTime = data?.arrivalTimeInUTC;
      this.arrivalDateTimeInUTC = this.formatDate(data?.arrivalTimeInUTC);
      this.depDateTimeInUTC = this.formatDate(data.depTimeInUTC);
      this.arrivalDateTimeInLT = this.formatArrivalDate(data);
      this.depDateTimeInLT = this.formatDepDate(data);
    }

  }
  public makeTripTime(addtime, time) {
    return timezones.utc(time).add(addtime, 'minutes').format();
  }
  public convertAirporttime(timezone, time) {
    if (timezone != '' && timezone) {
      return timezones.tz(time, timezone).format();
    } else {
      return timezones(time).format();
    }
  }
  private formatDate(dateString) {
    const dateOfJourney = new Date(dateString);
    const formattedDate = dateOfJourney.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) + ` T${dateOfJourney.toISOString().slice(11, 19)}Z`;
    return formattedDate;
  }
  private formatArrivalDate(data) {
    const arrivalLT = this.convertAirporttime(data.endtimeZone, this.makeTripTime(0, data.arrivalTimeInUTC));
    return arrivalLT;
  }
  private formatDepDate(data) {
    const depLT = this.convertAirporttime(data.starttimeZone,this.makeTripTime(0, data.departDate));
    return depLT;
  }
}
export class AvailableAircraft {
  public availableaircrafts: ListAvailableAircrafts[];
  public leglist: [];
}
export class ListAvailableAircrafts {
  public AircraftID: string = '';
  public ApiAircraftType: string = '';
  public BaseLocation: string = '';
  public ClientID: string = '';
  public ClientName: string = '';
  public NetCharter: string = '';
  public TaxiTime: string = '';
  public UserID: string = '';
  public minimumRunwayLength: string = '';
  public FixedCharterPrice: number;
  public Flighttime: string;
  public TotalBlktime: string;
  public actailnum: string;
  public logo: string;
  public NoOfCrewCapacity: string;
  public NoOfPaxCapacity: string;
  public coverImage?: string;
}
export class EditStatus {
  public type: string;
  public index: number;
}
export class CharterInformationDeatils {
  public BLKTIME: string;
  public DIST: string;
  public ETDGMT: string;
  public ETDLT: string;
  public ETAGMT: string;
  public ETALT: string;
  public legRouteid: string;
  public etops = 'yes';
  public aircraft: string;
  public Blktimeinhours: any;
  constructor(getNewQoute?: any) {
    if (getNewQoute) {
      this.BLKTIME = getNewQoute.EstFlightTime;
    }

  }
}