import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeFileExtn'
})
export class RemoveFileExtnPipe implements PipeTransform {

public transform(value: any, getExtn:boolean): any {
    if(!value) {
      return;
    }
    const rmextension =  value.split(".")
    .pop();
    const getFileName = value.split(".")
    .shift();
    return getExtn == false ? getFileName: rmextension == 'xlsx' ? 'Excel': 'PDF';
  }

}
