export class Service {
  public sector = new Array<TripServices>();
  public station = new Array<TripServices>();
  public loopList = {
    sector: {},
    station: {},
  };
  public legsDetails = new Array<ServiceLeg>();
}
export class ServiceLeg {
  public createdDate: string;
  public from: string;
  public to: string;
  public sector: string;
  public BLKTIME: string;
  public legid: string;
  public Legstage: string;
  public departDate: string;
  public countdownTime: number;
  public ActualLanding:any;
  public ActualTakeoff:any;
}
export class TripServices {
  public VendorOperationContactNo?: any;
  public vendorOpscallingCodes?: any;
  public ClientOperationContactNo?: any;
  public commonLeg?: boolean;
  public callingCodeOpscontact?: any;
  public checked?: boolean = false;
  public statusToggle?: boolean = false;
  public sectorToggle?: boolean = false;
  public SRID?: any;
  public legId: number;
  public serviceDetails?: any;
  public taxes?= [];
  public price: number;
  public qoutes?: any;
  public Status?: string;
  public status: string;
  public sector: string;
  public legStatus: string;
  public tripStatus: string;
  public order?: number;
  public location: string;
  public vendorid: any;
  public category?: any;
  public servicename: string;
  public serviceType: string;
  public requestType: string;
  public logo: string;
  public fuelDetailsInprogress?= [];
  public fuelDetails?: any;
  public subservicedetails?: any;
  public airlandingandslotpermits?: any;
  public departSecond?: string;
  public createSecond?: string;
  public timerCount?: number;
  public SRStnID?: string;
  public FlowID?: string;
  public flightPlanningUploaded?: boolean;
  public name?: string;
  public header?: string;
  public instantActivity?: boolean;
  public vendorID?: number;
  public isClickable?: boolean = true;
  public cbpprice?: string;
  public NavigationCost?: string;
  public progressDate?: string;
  public ActualLanding?: any;
  public ActualTakeoff?: any;
  public vendorIdservice?: number;
  public showStatus?: string;
  public note?: string;
  public flightPlanDetails?= [];
  public AcutalFuelBurn?: string;
  public AttachmentFileName?: string;
  public AttachmentName?: string;
  public crewDetails?: any;
  public firebaseUID?: string;
  public callingCodes?: string;
  public DriverCallingcode?: any;
  public fuelIntoplanAgentDetails?: any;
  public handlerdetails?: any;
  public previousSector?: any;
  public previousLegId?: any;
  public previousLegStatus?: boolean;
  public previousTimeOfService?: string;
  public quantity?: number;
  public selectFlightPlan?: any;
  public ArrDeptStatus?: boolean = false;
  public onTimeOfService?: any;
  public AircraftType?: string;
  public chatFirebaseId?: string;
  public legIndex?: any;
  public CrewBriefingAttachmentID?: number;
  public routeId?: string;
  public VendorID?: string;
  public BayNumber?: any;
  public CBPAttachmentDetails?: any;
  public Selected?: any;
  public DisableArray?: any;
  public FilePath?:any;
  public DepartureAttachments?:any;
  public ArrivalAttachments?:any;
  public arrivalOrDepature?: string;
}
export class FlightPlanArr {
  public AttachmentFileName: string;
  public AttachmentName: string;
  public Cbpname: string;
  public CrewBriefingAttachmentID: string;
  public Finalize: string;
  public RouteID: string;
  public SRID: number;
  public SRSecID: number;
  public Sector: string;
  public legID: string;
  public Selected: any;
  public FlightPlanRes: any;
  public ShortFiledFlightPlan: any;
  public Filed: any;
  public isDownloadOpen: boolean = false;
  public isShortICAO: boolean = false;
  public Valid: any = false;
  public RouteType: string;
  public VendorID: string;
  public legId?: number;
  public sector?: string;
  public respID?: string;
}

export class FlightTimeUpdate {
  public BLKTIME: any;
  public FuelLeft: any;
  public chocksoff: any;
  public chockson: any;
  public estflighttime: any;
  public landed: any;
  public takeoff: any;
  public FuelUnitType:any;
}

